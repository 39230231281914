import {
  Box,
  Button,
  capitalize,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import { OverflowTooltip } from '@/components/tooltips/overflowTooltip';
import { UserCircle } from '@/components/users/userBadge';
import { useRoles } from '@/contexts/useRoles';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';

import { ChangeScope } from './changeScope';

export const UserBox = ({
  setForceOpen,
}: {
  setForceOpen: (open: boolean) => void;
}) => {
  const dal = useDAL();
  const router = useRouter();
  const { user, currentProjectId } = useUser();
  const { rolesMap } = useRoles();
  const [mode, setMode] = useState('default');

  const { body: projects } = dal.projects.list();
  const name = user?.givenName
    ? capitalize(user?.givenName)
    : user?.email || '';

  const hasAccountRoles = Object.keys(user?.roleToProjectsMappings || {}).some(
    (roleId) => {
      const role = rolesMap[roleId];
      return role && !role.isProjectRole;
    }
  );

  return (
    <Box
      className='user-box slide-left collapse opacity-0 absolute top-[12px] left-[24px] right-[24px] rounded-[8px] z-5'
      sx={(theme) => ({
        boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.20)',
        background:
          mode === 'default' || theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, 0.10)'
            : 'white',
      })}
    >
      <Stack className='w-[257px] pl-[13px] pr-[16px] py-[16px]' gap='16px'>
        {mode === 'default' && (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Stack direction='row' alignItems='center'>
                <UserCircle name={name} />
                <OverflowTooltip
                  text={name}
                  renderContent={(text, ref) => (
                    <Typography
                      className='font-semibold text-white ml-[12px] truncate'
                      ref={ref}
                      sx={{
                        maxWidth: '150px',
                      }}
                    >
                      {text}
                    </Typography>
                  )}
                />
              </Stack>
              <OptionMenu
                iconClassName='text-white'
                iconButtonProps={{
                  sx: {
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.10) !important',
                    },
                  },
                }}
                icon='material-symbols-more-horiz'
                options={['Logout']}
                onOptionSelected={(option) => {
                  if (option === 'Logout') {
                    router.push('/logout');
                  }
                }}
              />
            </Stack>

            <Tooltip
              title='Current project and role'
              PopperProps={{ disablePortal: true }}
            >
              <Stack
                direction='row'
                className='rounded-[4px] p-[12px] cursor-pointer'
                alignItems='center'
                justifyContent='space-between'
                sx={{
                  background: 'rgba(255, 255, 255, 0.05)',

                  '&:hover': {
                    background: 'rgba(255, 255, 255, 0.10)',
                  },
                }}
                onClick={() => {
                  setMode('change-scope');
                  setForceOpen(true);
                }}
              >
                <Stack>
                  <Typography className='text-white'>
                    {
                      projects?.projects?.find((x) => x.id === currentProjectId)
                        ?.name
                    }
                  </Typography>
                  <Typography
                    className='text-white font-medium mt-[4px]'
                    variant='subtitle1'
                  >
                    {user?.selectedRole?.name}
                  </Typography>
                </Stack>
                <i className='material-symbols-arrow-drop-down-rounded text-white w-[24px] h-[24px]' />
              </Stack>
            </Tooltip>

            {hasAccountRoles && (
              <>
                <Divider
                  sx={{
                    borderColor: 'rgba(255, 255, 255, 0.05)',
                  }}
                />
                <Link
                  href='/global-settings'
                  className='h-[36px]'
                  prefetch={false}
                >
                  <Button
                    className='mx-auto'
                    sx={{
                      '&:not(.Mui-disabled):hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.10)',
                      },
                    }}
                  >
                    <Stack
                      gap='8px'
                      direction='row'
                      alignItems='center'
                      justifyContent='center'
                    >
                      <i className='material-symbols-settings-outline text-white w-[20px] h-[20px]' />
                      <Typography className='text-white font-medium'>
                        Global Settings
                      </Typography>
                    </Stack>
                  </Button>
                </Link>
              </>
            )}
          </>
        )}
        {mode === 'change-scope' && (
          <ChangeScope
            projects={projects?.projects || []}
            onCancel={() => {
              setMode('default');
              setForceOpen(false);
            }}
          />
        )}
      </Stack>
    </Box>
  );
};
