import { Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import RetentionSelector from '@/app/(dashboard)/settings/controls/retentionSelector';
import { BackupVaultSelection } from '@/components/vaults/backupVaultSelection';
import type { BackupVault } from '@/data/vaults/backupVault';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import {
  CalcRetentionFromSelector,
  type CalcOption,
} from './calcRetentionFromSelector';
import { ConvertToEonDetailedColumn } from './convertToEonDetailedColumn';
import { SnapTimeline } from './snapshotTimeline';

interface EonTargetDetailsProps {
  setSelectedVault: (vault: BackupVault) => void;
  selectedVault?: BackupVault;
  setRetentionInDays: (retentionInDays: number) => void;
  retentionInDays: number;
  calcOption: CalcOption;
  setCalcOption: (calcOption: CalcOption) => void;
  snapshotDate: Date;
  setIsInvalidRetention: (value: boolean) => void;
  isInvalidRetention: boolean;
}

export default function ConvertToEonTargetDetails(
  props: EonTargetDetailsProps
) {
  const {
    selectedVault,
    setSelectedVault,
    retentionInDays,
    setRetentionInDays,
    calcOption,
    setCalcOption,
    snapshotDate,
    setIsInvalidRetention,
    isInvalidRetention,
  } = props;
  const [expirationDate, setExpirationDate] = useState<Date>(snapshotDate);
  const { data: vaults, loading } = useBackupVaults();

  useEffect(() => {
    if (vaults) {
      setSelectedVault(vaults[0]);
    }
  }, [setSelectedVault, vaults]);

  const color =
    selectedVault?.backgroundColor || 'var(--mui-palette-primary-main)';

  useEffect(() => {
    const date = new Date(snapshotDate);
    date.setDate(date.getDate() + retentionInDays);
    setExpirationDate(date);
  }, [retentionInDays, snapshotDate]);

  useEffect(() => {
    const today = new Date();
    if (calcOption === 'snapshot-time') {
      setIsInvalidRetention(expirationDate <= today);
    } else {
      setIsInvalidRetention(false);
    }
  }, [calcOption, expirationDate, setIsInvalidRetention]);

  return (
    <Stack width='100%' gap='40px'>
      <Stack gap='24px' width='470px'>
        <Stack direction='row' justifyItems='start' gap='40px'>
          <ConvertToEonDetailedColumn>
            <Typography fontSize={14}>Retention</Typography>
            <RetentionSelector
              onChange={setRetentionInDays}
              initialRetentionDays={retentionInDays}
              initialFragment='days'
              isInvalidRetention={isInvalidRetention}
              invalidRetentionError='Retention period has passed. To save this snapshot, increase the retention time.'
            />
          </ConvertToEonDetailedColumn>
          <ConvertToEonDetailedColumn flexGrow={1}>
            <Typography fontSize={14}>Calculate from</Typography>
            <CalcRetentionFromSelector
              calcOption={calcOption}
              onChange={setCalcOption}
            />
          </ConvertToEonDetailedColumn>
        </Stack>
        {calcOption === 'snapshot-time' && (
          <SnapTimeline
            hidePointDateLabel={true}
            pointDate={snapshotDate}
            DotIcon={null}
            showTimeline={true}
            expirationDate={expirationDate}
            color={color}
          />
        )}
      </Stack>
      <Divider />
      <Stack paddingBottom='24px' gap='24px'>
        <Typography variant='subtitle1' fontSize={14}>
          Store in vault:
        </Typography>
        <BackupVaultSelection
          onSelect={setSelectedVault}
          selectedVaults={selectedVault ? [selectedVault.id] : []}
          loading={loading}
          vaults={vaults}
        />
      </Stack>
    </Stack>
  );
}
