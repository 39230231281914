import type {
  InventoryResource,
  Snapshot,
  AzureVmDisk,
  InventorySnapshotVolume,
} from '@repo/api-gw-sdk';

import { MultiAccordionsItem } from '@/components/accordion/multiAccordionsItem';
import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotDetailsBody } from './snapshotDetailsBody';
import { SnapshotDetailsHeader } from './snapshotDetailsHeader';
import type { RestoreActionType } from './vmSnapshotRow';

export const SnapshotDetails = ({
  snap,
  vault,
  isSelected,
  onSelect,
  onRestore,
  resource,
  onConvertSnapshot,
  snapshotSelection: snapshotSelection,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected: boolean;
  onSelect: () => void;
  onRestore: (
    volumes: (AzureVmDisk | InventorySnapshotVolume)[],
    actionType: RestoreActionType
  ) => void;
  onConvertSnapshot?: (
    volumes: (AzureVmDisk | InventorySnapshotVolume)[],
    actionType: RestoreActionType
  ) => void;
  resource: InventoryResource;
  snapshotSelection: boolean;
}) => {
  return (
    <MultiAccordionsItem
      isMulti={snapshotSelection}
      isSelected={isSelected}
      onSelect={onSelect}
      Summary={
        <SnapshotDetailsHeader
          snap={snap}
          vault={vault}
          isSelected={isSelected}
          onSelect={onSelect}
          snapshotSelection={false}
        />
      }
      Details={
        <SnapshotDetailsBody
          snapshot={snap}
          vault={vault}
          onRestore={onRestore}
          resource={resource}
          onConvertSnapshot={onConvertSnapshot}
        />
      }
    />
  );
};
