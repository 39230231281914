import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import SelectPlaceholder from '../selectPlaceholder';

const title = 'Region';

export const RegionSelect = ({
  accountId,
  regionName,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  onChange: (regionName?: string, isUserChange?: boolean) => void;
}) => {
  if (!accountId) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerRegionSelect
      accountId={accountId}
      regionName={regionName}
      onChange={onChange}
    />
  );
};

const InnerRegionSelect = ({
  accountId,
  regionName,
  onChange,
}: {
  accountId: string;
  regionName?: string;
  onChange: (regionName?: string, isUserChange?: boolean) => void;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.regions.list(accountId);
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (regionName && !body?.regions.includes(regionName)) {
      onChange(undefined);
    }
  }, [body?.regions, isLoading, onChange, regionName]);

  if (!body?.regions.length) {
    return <EmptySelect title={title} />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        size='small'
        value={regionName || ''}
        onChange={(event) => onChange(event.target.value, !!event)}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
      >
        {body.regions.map((region) => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
