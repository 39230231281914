'use client';

import { Snackbar } from '@mui/material';

import { useWorkspace } from '@/contexts/useWorkspace';

export const InfoSnackbar = () => {
  const { snackbar } = useWorkspace();

  return (
    <Snackbar
      sx={{ marginLeft: '62px' }}
      open={snackbar.isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      autoHideDuration={3000}
      onClose={() => snackbar.hide()}
      message={snackbar.message}
    />
  );
};
