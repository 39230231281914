import { Tooltip } from '@mui/material';

interface DbLinkProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLocation: any;
}

export const DBLink = (props: DbLinkProps) => {
  const { dataLocation } = props;
  if (dataLocation?.database && dataLocation?.table && dataLocation?.column) {
    return (
      <>
        <Tooltip title='Database Name'>
          <span>[{dataLocation?.database}]</span>
        </Tooltip>
        .
        <Tooltip title='Table Name'>
          <span>[{dataLocation?.table}]</span>
        </Tooltip>
        .
        <Tooltip title='Column Name'>
          <span>[{dataLocation?.column}]</span>
        </Tooltip>
      </>
    );
  }
};
