'use client';

import {
  Box,
  Button,
  ClickAwayListener,
  Fade,
  FormControlLabel,
  Paper,
  Popper,
  Stack,
  Switch,
  Tooltip,
  Typography,
  type IconProps,
} from '@mui/material';
import { Provider } from '@repo/api-gw-sdk';
import classNames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { useContext, useRef, useState } from 'react';

import { Icon } from '@/components/shared/icon';
import { ThemeContext } from '@/components/theme';
import { useEnvironment } from '@/contexts/useEnvironment';
import { CloudProviders } from '@/data/inventory/data';

export const MenuItem = ({
  href,
  title,
  iconClass,
  testId,
  disabled,
}: {
  title: string;
  href: string;
  iconClass: string;
  testId?: string;
  disabled?: boolean;
}) => {
  const pathname = usePathname();
  const selected = pathname.startsWith(href);

  return disabled ? (
    <Box
      className={`${classNames(
        'slide-left',
        {}
      )} w-[60px] h-[60px] cursor-default flex items-center justify-middle`}
    >
      <i
        className={`${iconClass} w-[24px] h-[24px] ml-[18px] text-[#a1a1aa]`}
      />
      <Typography
        className={classNames(
          'slide-left collapse absolute left-[60px] w-[200px] opacity-0 text-[#a1a1aa]'
        )}
      >
        {title}
      </Typography>
    </Box>
  ) : (
    <Link
      href={href}
      data-testid={testId}
      className={classNames('slide-left', {
        'bg-white': selected,
        'text-[var(--mui-palette-background-dark)]': selected,
        'hover:bg-[rgba(255,255,255,0.10)]': !selected,
      })}
    >
      <i className={`${iconClass} w-[24px] h-[24px] ml-[18px]`} />
      <Typography
        className={classNames(
          'slide-left collapse absolute left-[60px] w-[200px] opacity-0',
          {
            'text-[var(--mui-palette-background-dark)]': selected,
            'text-white': !selected,
          }
        )}
      >
        {title}
      </Typography>
    </Link>
  );
};

interface ActionIconProps extends IconProps {
  title: string;
  isActive: boolean;
  iconClass: string;
  onClick: () => void;
}

const ActionIcon = ({
  title,
  isActive,
  iconClass,
  onClick,
  ...rest
}: ActionIconProps) => {
  return (
    <Tooltip
      title={title}
      className='mr-[12px]'
      PopperProps={{ disablePortal: true }}
    >
      <Icon
        {...rest}
        sx={{
          backgroundColor: isActive
            ? 'white'
            : 'var(--mui-palette-background-dark)',

          '&:hover': {
            backgroundColor: isActive ? 'white' : 'rgba(255, 255, 255, 0.10)',
          },

          '& i': {
            color: isActive ? 'var(--mui-palette-background-dark)' : 'white',
          },
        }}
        iconClass={iconClass}
        isActive={isActive}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export const NavigationActions = () => {
  const { theme, changeTheme } = useContext(ThemeContext);
  const isDarkMode = theme === 'dark';
  const { isDemo, isDev } = useEnvironment();

  return (
    <Box className='navigation-actions slide-left collapse opacity-0 absolute left-[24px] right-[24px] bottom-[24px] '>
      <Stack
        direction='row'
        className='w-[257px]'
        alignItems='center'
        justifyContent='space-between'
      >
        <Stack direction='row'>
          <ActionIcon
            data-testid='light-mode'
            title='Light mode'
            isActive={!isDarkMode}
            iconClass='material-symbols-light-mode-outline-rounded'
            onClick={() => changeTheme('light')}
          />
          <ActionIcon
            data-testid='dark-mode'
            title='Dark mode'
            isActive={isDarkMode}
            iconClass='material-symbols-bedtime-outline-rounded'
            onClick={() => changeTheme('dark')}
          />
        </Stack>
        {(isDemo || isDev) && <DemoSettingsMenu />}
        <Link
          prefetch={false}
          href='/docs'
          className='items-end flex-col justify-center'
          target='_blank'
        >
          <Button
            variant='outlined'
            sx={{
              borderColor: 'white',
              background: 'transparent',
              color: 'white',
            }}
          >
            <i className='material-symbols-help-outline-rounded w-[20px] h-[20px] mr-[8px]' />
            Eon Docs
          </Button>
        </Link>
      </Stack>
    </Box>
  );
};

const DemoSettingsMenu = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { demoSettings, updateDemoSettings } = useEnvironment();

  return (
    <>
      <Icon
        ref={anchorRef}
        onClick={(event) => {
          event.stopPropagation();
          setOpen((prevOpen) => !prevOpen);
        }}
        iconClass='material-symbols-settings-outline'
        sx={{
          cursor: 'pointer',
          backgroundColor: 'var(--mui-palette-background-dark)',

          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.10)',
          },

          '& i': {
            color: 'white',
          },
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        sx={{ zIndex: 5 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <Paper className={'shadow-lg'}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Stack
                  sx={{
                    width: '200px',
                    padding: '24px',
                  }}
                  gap='24px'
                >
                  <Typography variant='h3'>Console Settings</Typography>
                  <Stack gap='12px'>
                    {(
                      [
                        Provider.Aws,
                        Provider.Azure,
                        Provider.Gcp,
                        Provider.MongoAtlas,
                      ] as const
                    ).map((provider) => (
                      <FormControlLabel
                        key={provider}
                        control={<Switch className='mr-[12px]' />}
                        label={CloudProviders[provider].displayName}
                        checked={demoSettings[provider]}
                        onChange={(event, checked) =>
                          updateDemoSettings({
                            ...demoSettings,
                            [provider]: checked,
                          })
                        }
                      />
                    ))}
                    <FormControlLabel
                      control={<Switch className='mr-[12px]' />}
                      label='Ransomware'
                      checked={demoSettings.ransomware}
                      onChange={(event, checked) =>
                        updateDemoSettings({
                          ...demoSettings,
                          ransomware: checked,
                        })
                      }
                    />
                    <Button
                      variant='outlined'
                      onClick={() => window.location.reload()}
                    >
                      Reload
                    </Button>
                  </Stack>
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
