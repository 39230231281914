import { Stack, Box, Table, TableBody, Tooltip, TableRow } from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { DataClass } from '@repo/api-gw-sdk';
import { Roboto_Mono } from 'next/font/google';

import { DBLink } from '@/components/explorer/dbLink';
import { FileLink, isWindowsMachine } from '@/components/explorer/fileLink';
import { Loader } from '@/components/layout/loading';
import { Icon } from '@/components/shared/icon';
import { BorderlessTableCell } from '@/components/table/components';
import { useRoles } from '@/contexts/useRoles';
import { useDAL } from '@/data/dal';
import { DataClasses, PiiStringTypes, PiiStrings } from '@/data/inventory/data';

import { AutoClassification } from './autoClassifcationButtons';

const roboto = Roboto_Mono({
  weight: '600',
  subsets: ['latin'],
});

export function DataClassViewer({
  entity,
  setDataClassOpen: setDataClassEditorOpen,
  setRestartDetectionModalOpen,
}: {
  entity: InventoryResource;
  setDataClassOpen: (e: React.MouseEvent<HTMLElement>) => void;
  setRestartDetectionModalOpen: (e: React.MouseEvent<HTMLElement>) => void;
}) {
  const { isAuthorizedResource } = useRoles();
  const dal = useDAL();

  const { body } = dal.inventory.dataClasses.list(entity.id);

  if (!body) {
    return <Loader />;
  }
  const examples = body.dataClassificationEntities;

  if (entity.classifications?.dataClassesDetails?.isOverridden) {
    return (
      <>
        {' '}
        <AutoClassification
          testId='dataclass-auto-classification-buttons-overridden'
          textOn={''}
          isAutoClassificationOn={false}
          restartDetectionClicked={(e) => setRestartDetectionModalOpen(e)}
          editOverridesClicked={(e) => setDataClassEditorOpen(e)}
          setEditorOpen={(e) => setDataClassEditorOpen(e)}
          disabled={!isAuthorizedResource('update:data_classification', entity)}
        />
        {!!entity.classifications.dataClassesDetails.dataClasses?.length &&
          Object.values(DataClass)
            .filter((x) =>
              entity.classifications!.dataClassesDetails!.dataClasses!.includes(
                x
              )
            )
            .map((x) => (
              <Stack
                key={x}
                direction='row'
                sx={{ height: '48px' }}
                alignItems='center'
              >
                <Box className='mr-6'>
                  <Icon
                    iconClass='material-symbols-check'
                    sx={{ height: '24px' }}
                  />
                </Box>
                <Tooltip title={DataClasses[x].description}>
                  <Box>{x}</Box>
                </Tooltip>
              </Stack>
            ))}
      </>
    );
  }

  const textOn = examples.length
    ? 'Data classes were detected. Here are few samples:'
    : 'No data classes were detected';

  const isWindows = isWindowsMachine(
    examples.map((x) => x.dataLocation?.location)
  );

  return (
    <>
      <AutoClassification
        textOn={textOn}
        testId='dataclass-auto-classification-buttons-auto'
        isAutoClassificationOn={true}
        restartDetectionClicked={(e) => setRestartDetectionModalOpen(e)}
        editOverridesClicked={(e) => setDataClassEditorOpen(e)}
        setEditorOpen={(e) => setDataClassEditorOpen(e)}
        disabled={!isAuthorizedResource('update:data_classification', entity)}
      />
      {!!examples.length && (
        <Table>
          <TableBody>
            {examples?.map((x) => (
              <TableRow key={String(x.id)} sx={{ height: '56px' }}>
                <Tooltip
                  title={DataClasses[PiiStringTypes[x.entityType]].description}
                >
                  <BorderlessTableCell className='font-semibold w-2/12'>
                    {PiiStringTypes[x.entityType] ?? '??'}
                  </BorderlessTableCell>
                </Tooltip>
                <BorderlessTableCell className='w-4/12'>
                  {PiiStrings[x.entityType] ?? 'Unknown'}
                </BorderlessTableCell>
                <BorderlessTableCell className={roboto.className}>
                  {typeof x.dataLocation?.location === 'string' ? (
                    <FileLink
                      entity={entity}
                      location={x.dataLocation.location}
                      isWindowsMachine={isWindows}
                    />
                  ) : (
                    <DBLink dataLocation={x.dataLocation} />
                  )}
                </BorderlessTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
}
