import {
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import type { InventoryResource } from '@repo/api-gw-sdk';
import { Fragment } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/accordion';
import { volumePresets } from '@/components/volumes/volumeSettings';
import { fileSizeFormatter } from '@/utils/fileSizeFormatter';

import { SidePanelPropsTable } from './shared';

export const EKSNamespaceSection = ({
  resource,
}: {
  resource: InventoryResource;
}) => {
  return (
    <Stack>
      {resource.resourceProperties?.awsEksNamespace?.secretsCount && (
        <SidePanelPropsTable sx={{ marginBottom: '12px' }}>
          <TableBody>
            <TableRow>
              <TableCell
                className='pb-[20px] pl-[40px]'
                sx={{
                  width: '210px',
                  verticalAlign: 'top',
                }}
              >
                Secrets count
              </TableCell>
              <TableCell className='pb-[20px]'>
                {resource.resourceProperties?.awsEksNamespace?.secretsCount}
              </TableCell>
            </TableRow>
          </TableBody>
        </SidePanelPropsTable>
      )}
      <Stack>
        {resource.resourceProperties?.awsEksNamespace?.persistentVolumeClaims?.map(
          (volume, index, volumes) => {
            return (
              <Fragment key={volume.providerResourceId}>
                <Accordion className='px-[40px]'>
                  <AccordionSummary>
                    <Typography className='font-semibold'>
                      {volume.pvcName}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className='py-[12px] pl-[36px] pr-0'>
                    <Table
                      className='w-full'
                      sx={{
                        td: {
                          padding: '4px 0',
                          borderBottom: 'none',
                        },

                        '& .props-col': {
                          verticalAlign: 'top',
                          fontWeight: '400',
                          width: '140px',
                        },
                      }}
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell className='props-col'>Volume ID</TableCell>
                          <TableCell>{volume.providerResourceId}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='props-col'>PV name</TableCell>
                          <TableCell>{volume.pvName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='props-col'>
                            Availability zone
                          </TableCell>
                          <TableCell>{volume.availabilityZone}</TableCell>
                        </TableRow>
                        {volume.fsType && (
                          <TableRow>
                            <TableCell className='props-col'>FS type</TableCell>
                            <TableCell>{volume.fsType}</TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell className='props-col'>Type</TableCell>
                          <TableCell>
                            {
                              volumePresets[volume.volumeSettings.type]
                                ?.displayName
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className='props-col'>Size</TableCell>
                          <TableCell>
                            {fileSizeFormatter(
                              volume.volumeSettings.sizeBytes,
                              1,
                              false
                            )}
                          </TableCell>
                        </TableRow>
                        {volume.volumeSettings.iops && (
                          <TableRow>
                            <TableCell className='props-col'>IOPS</TableCell>
                            <TableCell>{volume.volumeSettings.iops}</TableCell>
                          </TableRow>
                        )}
                        {volume.volumeSettings.throughput && (
                          <TableRow>
                            <TableCell className='props-col'>
                              Throughput
                            </TableCell>
                            <TableCell>{`${volume.volumeSettings.throughput} MB/s`}</TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
                {index < volumes.length - 1 && (
                  <Divider className='my-[12px]' />
                )}
              </Fragment>
            );
          }
        )}
      </Stack>
    </Stack>
  );
};
