import type {
  InventoryResource,
  Snapshot,
  Account,
  VolumeSettings,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { ConvertToEbsSnapshotConfiguration as ConvertToEbsSnapshotConfiguration } from './steps/convertToEbsSnapshotConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { Encryption } from '../encryption/encryptionComponent';
import type { RestoreActionType } from '../panels/instance/tabs/vmSnapshotRow';
import type { ResourcePanelTabs } from '../panels/instancePanel';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreVolumeState {
  providerVolumeId: string;
  actionType: RestoreActionType;
  resourceId: string;
  snapshot: Snapshot;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  regionName?: string;
  availabilityZone?: string;
  tags?: Record<string, string>;
  initialTags?: Record<string, string>;
  keepOriginalTags?: boolean;
  volumeSettings?: VolumeSettings;
  initialVolumeSettings?: VolumeSettings;
  keepOriginalSettings?: boolean;
  encryption?: Encryption;
}

const restoreVolumeFlow: Step<RestoreVolumeState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'ConvertToEbsSnapshotConfiguration',
      Component: ConvertToEbsSnapshotConfiguration,
    },
  ],
};

export const RestoreVolumeWizard = ({
  resource,
  snapshot,
  providerVolumeId,
  volumeRegion,
  actionType,
  originTab,
}: {
  resource: InventoryResource;
  snapshot: Snapshot;
  providerVolumeId: string;
  volumeRegion: string;
  actionType: RestoreActionType;
  originTab: ResourcePanelTabs;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;
  const volumeProperties = snapshot.resource?.properties?.awsEc2?.volumes?.find(
    (x) => x.providerVolumeId === providerVolumeId
  );

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resource.id,
        onClose: () => setIsOpen(false),
        initialTab: originTab,
      },
    });

    return true;
  };

  if (!volumeProperties) {
    return null;
  }

  const getTitle = () => {
    switch (actionType) {
      case 'convert-snapshot':
        return 'Convert to EBS Snapshot';
      case 'convert-image':
        return 'Convert to AMI';
      default:
        return 'Restore Volume';
    }
  };

  return (
    <Wizard<RestoreVolumeState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreVolumeFlow]}
      initialState={{
        providerVolumeId,
        actionType,
        resourceId: resource.id,
        availabilityZone: volumeProperties.availabilityZone,
        initialVolumeSettings: volumeProperties.volumeSettings,
        initialTags: volumeProperties.tags || undefined,
        regionName: volumeRegion,
        snapshot,
        supportedCloudProviders: [Provider.Aws],
        keepOriginalTags: true,
        keepOriginalSettings: true,
      }}
      title={getTitle()}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
