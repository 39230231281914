import { Stack } from '@mui/material';
import type { ReactNode } from 'react';

import { SnapTimeline } from './snapshotTimeline';

interface OldestLatestSnapshotsProps {
  oldestDate: Date;
  latestDate: Date;
  oldestExpirationDate?: Date;
  latestExpirationDate?: Date;
  DotIcon: ReactNode;
  showTimeline?: boolean;
  color?: string;
}

export default function OldestLatestSnapshots(
  props: OldestLatestSnapshotsProps
) {
  const {
    oldestDate,
    latestDate,
    oldestExpirationDate,
    latestExpirationDate,
    DotIcon,
    showTimeline,
    color,
  } = props;

  return (
    <Stack
      width='350px'
      gap='16px'
      sx={{
        '& .MuiTypography-root': {
          lineHeight: '24px',
        },
      }}
    >
      <SnapTimeline
        label='Oldest'
        pointDate={oldestDate}
        DotIcon={DotIcon}
        showTimeline={showTimeline}
        expirationDate={oldestExpirationDate}
        color={color}
      />
      <SnapTimeline
        label='Latest'
        pointDate={latestDate}
        DotIcon={DotIcon}
        showTimeline={showTimeline}
        expirationDate={latestExpirationDate}
        color={color}
      />
    </Stack>
  );
}
