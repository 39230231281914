import { Box, Button, Popover, Typography } from '@mui/material';
import type { BackupSchedule } from '@repo/api-gw-sdk';
import { useRef, useState } from 'react';

import { fromDaysToBiggestAsPossible } from '@/utils/dateTime';

import { frequencyMapping } from './MinimumRetentionEditor';
import RetentionSelector from './retentionSelector';

interface RetentionPopoverProps {
  schedule: BackupSchedule;
  onUpdate: (schedule: BackupSchedule) => void;
}

export const RetentionPopover = (props: RetentionPopoverProps) => {
  const { schedule, onUpdate } = props;
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const updateRetention = (retentionInDays: number) => {
    const fragment = frequencyMapping.find(
      (x) => x.windows === schedule.windows
    );
    if (!fragment) {
      return;
    }

    const backupRetention = Math.max(
      retentionInDays,
      fragment.minimumRetentionInDays
    );

    onUpdate({
      ...schedule,
      backupRetention,
    });
  };

  const formattedRetention = fromDaysToBiggestAsPossible(
    schedule.backupRetention || 1
  );

  return (
    <>
      <Button
        sx={{
          padding: '5px 12px',
        }}
        ref={anchorRef}
        onClick={() => setPopoverOpen(true)}
        data-testid='create-backup-policy-retention-select'
        disableFocusRipple
      >
        <Typography
          fontWeight={600}
        >{`${formattedRetention.value} ${formattedRetention.unit}`}</Typography>

        <i className='material-symbols-arrow-drop-down-rounded text-[22px] ml-[4px]' />
      </Button>
      <Popover
        disablePortal
        open={popoverOpen}
        anchorEl={anchorRef.current}
        onClose={() => setPopoverOpen(false)}
      >
        <Box className='flex flex-col p-[24px] gap-[24px] w-[300px]'>
          <Typography variant='subtitle1'>Choose a retention time</Typography>
          <RetentionSelector
            initialFragment={formattedRetention.unit}
            initialRetentionDays={schedule.backupRetention}
            onChange={updateRetention}
          />
        </Box>
      </Popover>
    </>
  );
};
