import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import { MultiSelect } from '../select/multiSelect';
import SelectPlaceholder from '../selectPlaceholder';

export const SecurityGroupSelect = ({
  accountId,
  regionName,
  securityGroupIds,
  onChange,
  vpc,
  isVpcMandatory,
  subnetId,
  isSubnetIdMandatory,
  isOptionalField,
  hideLabel,
  isMultiSelect,
}: {
  isMultiSelect?: boolean;
  accountId?: string;
  regionName?: string;
  securityGroupIds?: string[];
  vpc?: string;
  isVpcMandatory?: boolean;
  subnetId?: string;
  isSubnetIdMandatory?: boolean;
  onChange: (securityGroupIds?: string[], isUserChange?: boolean) => void;
  isOptionalField?: boolean;
  hideLabel?: boolean;
}) => {
  const title = `Security groups${isOptionalField ? ' (optional)' : ''}`;
  if (
    !accountId ||
    !regionName ||
    (isVpcMandatory && !vpc) ||
    (isSubnetIdMandatory && !subnetId)
  ) {
    return <EmptySelect title={hideLabel ? '' : title} />;
  }

  return (
    <InnerSecurityGroupSelect
      isMultiSelect={isMultiSelect}
      vpc={vpc}
      subnetId={subnetId}
      accountId={accountId}
      regionName={regionName}
      securityGroupIds={securityGroupIds}
      onChange={onChange}
      title={title}
      hideLabel={hideLabel}
    />
  );
};

const InnerSecurityGroupSelect = ({
  isMultiSelect,
  vpc,
  subnetId,
  accountId,
  regionName,
  securityGroupIds,
  onChange,
  title,
  hideLabel,
}: {
  isMultiSelect?: boolean;
  accountId: string;
  regionName: string;
  vpc?: string;
  subnetId?: string;
  securityGroupIds?: string[];
  onChange: (securityGroupIds?: string[], isUserChange?: boolean) => void;
  title: string;
  hideLabel?: boolean;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.securityGroups.list(
    accountId,
    regionName,
    vpc,
    subnetId
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (
      securityGroupIds?.length &&
      securityGroupIds.some(
        (sg) => !body?.securityGroups?.map((x) => x.id).includes(sg)
      )
    ) {
      onChange(undefined);
    }
  }, [body?.securityGroups, isLoading, onChange, securityGroupIds]);

  if (!body?.securityGroups.length) {
    return <EmptySelect title={hideLabel ? '' : title} />;
  }

  const options = body.securityGroups.map((group) => ({
    value: group.id,
    label: group.name,
  }));

  return (
    <FormControl size='small' className='flex-1'>
      {!hideLabel && <FormLabel>{title}</FormLabel>}
      {isMultiSelect ? (
        <MultiSelect
          multiple
          data-testid='securityGroupSelect'
          value={securityGroupIds || []}
          options={options}
          onChange={(event) =>
            onChange(event.target.value as string[], !!event)
          }
        />
      ) : (
        <Select
          data-testid='securityGroupSelect'
          size='small'
          value={securityGroupIds?.[0] || ''}
          onChange={(event) => onChange([event.target.value], !!event)}
          displayEmpty
          renderValue={(value) => {
            if (!value) {
              return <SelectPlaceholder />;
            }
            return body.securityGroups.find((group) => group.id === value)
              ?.name;
          }}
        >
          <MenuItem key={'select'} value={undefined}>
            <Typography
              sx={{ alignSelf: 'center' }}
              color='var(--mui-palette-text-gray1)'
            >
              Select
            </Typography>
          </MenuItem>
          {body.securityGroups.map((value, idx) => (
            <MenuItem
              data-testid={`securityGroupOption-${idx}`}
              key={value.id}
              value={value.id}
            >
              {value.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};
