import Link from 'next/link';

interface ReadMoreLinkProps {
  text?: string;
  href: string;
  hideArrow?: boolean;
}

export const ReadMoreLink = (props: ReadMoreLinkProps) => {
  const text = props.text || 'Learn more';
  const textWithArrow = `${text}${props.hideArrow ? '' : ' →'}`;
  return (
    <Link href={props.href} prefetch={false} className='font-bold underline'>
      {textWithArrow}
    </Link>
  );
};
