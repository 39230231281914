import { Stack, Typography } from '@mui/material';

interface SnapshotSelectionEmptyStateProps {
  description: string;
  icon?: string;
}

export default function SnapshotSelectionEmptyState(
  props: SnapshotSelectionEmptyStateProps
) {
  const { description, icon } = props;

  return (
    <Stack
      width={'100%'}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      spacing={'24px'}
    >
      {icon && <i className={`${icon} w-[24px] h-[24px]`} />}
      <Typography variant='h3'>{description}</Typography>
    </Stack>
  );
}
