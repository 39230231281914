import type {
  Account,
  RestoreInstanceVolumeInput,
  Snapshot,
  ResourceSnapshot,
  InventorySnapshotVolume,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { InstanceConfiguration } from './steps/instanceConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';
import { VolumeConfiguration } from './steps/volumeConfiguration';

import type { Encryption } from '../encryption/encryptionComponent';
import type { ResourcePanelTabs } from '../panels/instancePanel';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export type VolumeConfigState = RestoreInstanceVolumeInput & {
  encryption?: Encryption;
  keepOriginalTags: boolean;
  keepOriginalSettings: boolean;
};

export interface RestoreBaseState {
  regionName?: string;
  AWSSubnetId?: string;
  securityGroupIds?: string[];
  instanceType?: string;
  instanceProfile?: string;
  tags?: Record<string, string>;
  keepOriginalTags?: boolean;
  volumesConfigState?: VolumeConfigState[];
}

export interface RestoreEc2InstanceState {
  resourceId: string;
  snapshot: Snapshot;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  sharedEncryption?: Encryption;
  crossStepsState: RestoreBaseState;
  initialState: RestoreBaseState;
  volumes: InventorySnapshotVolume[];
}

const restoreEc2InstanceFlow: Step<RestoreEc2InstanceState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'InstanceConfiguration',
      Component: InstanceConfiguration,
      next: [
        {
          name: 'VolumeConfiguration',
          Component: VolumeConfiguration,
        },
      ],
    },
  ],
};

export const RestoreEc2InstanceWizard = ({
  resourceId,
  snapshot,
  sourceRegion,
  volumes,
  resourceProperties,
  initialTab,
}: {
  resourceId: string;
  snapshot: Snapshot;
  sourceRegion?: string;
  volumes: InventorySnapshotVolume[];
  resourceProperties?: ResourceSnapshot;
  initialTab: ResourcePanelTabs;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resourceId,
        onClose: () => setIsOpen(false),
        initialTab,
      },
    });

    return true;
  };

  const tags = resourceProperties?.tags || {};
  const volumesSettings: VolumeConfigState[] = volumes.map((vol) => ({
    ...vol,
    tags: vol.tags || {},
    isEncrypted: true,
    encryptionKeyId: undefined,
    keepOriginalTags: true,
    keepOriginalSettings: true,
  }));

  return (
    <Wizard<RestoreEc2InstanceState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreEc2InstanceFlow]}
      initialState={{
        snapshot,
        resourceId,
        supportedCloudProviders: [Provider.Aws],
        volumes,
        sharedEncryption: {
          isEncrypted: true,
          encryptionKeyId: undefined,
          arn: undefined,
          mode: 'select',
        },
        crossStepsState: {
          volumesConfigState: volumesSettings,
        },
        initialState: {
          regionName: sourceRegion || 'us-east-1',
          AWSSubnetId: resourceProperties?.properties?.awsEc2?.subnetId,
          securityGroupIds:
            resourceProperties?.properties?.awsEc2?.securityGroupIds,
          instanceProfile:
            resourceProperties?.properties?.awsEc2?.instanceProfileName,
          instanceType: resourceProperties?.properties?.awsEc2?.instanceType,
          tags,
          keepOriginalTags: true,
          volumesConfigState: volumesSettings,
        },
      }}
      title={`Restore EC2 Instance`}
      stepperLabels={[
        'Restore account',
        'Instance configuration',
        'Volume configuration',
      ]}
    />
  );
};
