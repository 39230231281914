import {
  type Account,
  type InventoryResource,
  type ResourceType,
  type Snapshot,
  Provider,
} from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { RestoreGenericStorageProps } from './bucketSelection';
import { S3Configuration } from './steps/s3Configuration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { ResourcePanelTabs } from '../panels/instancePanel';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreS3State extends RestoreGenericStorageProps {
  resourceType: ResourceType;
  resourceId: string;
  snapshot: Snapshot;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
}

const restoreS3Flow: Step<RestoreS3State> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'S3Configuration',
      Component: S3Configuration,
    },
  ],
};

export const RestoreS3Wizard = ({
  resource,
  snapshot,
  originTab,
}: {
  resource: InventoryResource;
  snapshot: Snapshot;
  sourceRegion?: string;
  originTab: ResourcePanelTabs;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resource.id,
        onClose: () => setIsOpen(false),
        initialTab: originTab,
      },
    });

    return true;
  };

  return (
    <Wizard<RestoreS3State>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreS3Flow]}
      initialState={{
        snapshot,
        resourceId: resource.id,
        resourceType: resource.resourceType,
        restoreMode: 'select',
        S3: {},
        StorageAccount: {},
        supportedCloudProviders: [Provider.Aws, Provider.Azure],
      }}
      title={
        resource.cloudProvider === Provider.Azure
          ? 'Restore Storage Account'
          : 'Restore S3'
      }
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
