import { Stack } from '@mui/material';
import { SnapshotStatus, type InventoryResource } from '@repo/api-gw-sdk';
import { useMemo, useState } from 'react';

import { isHaveSecurityIssue } from '@/components/security/securityUtils';
import {
  PointsInTimeCalendar,
  type PointInTimeEvent,
} from '@/components/snapshots/pointsInTimeCalendar';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';
import dayjs, { DATE_FORMAT } from '@/utils/dayjs';

import OtherSnapshotsToggle from './otherSnapshotsToggle';
import { RestoreSnapshotDrawer } from './restoreSnapshotDrawer';

import ConvertToEonSnapshotsBar from '../../../bars/convertToEonSnapshotsBar';

export const EonSnapshotsTab = ({ entity }: { entity: InventoryResource }) => {
  const { convertToEonSnapshot: convertToEonSnapshotEnabled } =
    useFeatureFlags();
  const dal = useDAL();
  const [snapshotDataOpen, setSnapshotDataOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<Date | undefined>();
  const [showOtherSnapshots, setShowOtherSnapshots] = useState(false);
  const { data: vaults } = useBackupVaults();
  const [startEndDates, setStartEndDates] = useState<
    | {
        start: Date;
        end: Date;
      }
    | undefined
  >();
  const { showPendingSnapshots, securityScan } = useFeatureFlags();
  const { isDemo, isDev, demoSettings } = useEnvironment();
  const securityScanEnabled =
    isDemo || isDev ? demoSettings.ransomware : securityScan;

  const statuses = [
    SnapshotStatus.Completed,
    ...(showPendingSnapshots ? [SnapshotStatus.Pending] : []),
  ];
  const { body: eonSnapshots, isLoading } = dal.inventory.snapshots.list(
    entity.id,
    0,
    200,
    statuses,
    startEndDates?.start,
    startEndDates?.end
  );

  const { body: otherSnapshots } = convertToEonSnapshotEnabled
    ? dal.convert.listDailySnapshots(entity.id)
    : { body: { dailySnapshots: [] } };

  const snapshots = useMemo(() => {
    return (eonSnapshots?.data || []).filter((snap) => {
      if (snap.status === SnapshotStatus.Pending) {
        return true;
      }

      if (
        snap.status === SnapshotStatus.Completed &&
        isDateValid(snap.pointInTime) &&
        snap.vaultId
      ) {
        return vaults.some((v) => v.id === snap.vaultId);
      }
    });
  }, [eonSnapshots?.data, vaults]);

  const eonSnapsEvents: PointInTimeEvent[] = snapshots.map((x) => {
    const vault = vaults.find((v) => v.id === x.vaultId);

    const date = isDateValid(x.pointInTime) ? x.pointInTime : x.createdTime;

    const end = new Date(date);
    end.setMinutes(end.getMinutes() + 1);

    return {
      start: date,
      end,
      vaultName: vault?.name,
      indicatorColor: vault?.backgroundColor || null,
      status: x.status,
      accessDeniedItemsCount: x.accessDeniedItemsCount,
      hasSecurityIssue: isHaveSecurityIssue(securityScanEnabled, x),
    };
  });

  const seen = new Set();
  let hasProviderSnapsInDate = false;
  const otherSnapsEvents: PointInTimeEvent[] = (
    showOtherSnapshots && otherSnapshots?.dailySnapshots
      ? otherSnapshots.dailySnapshots
      : []
  )
    .filter((x) => isDateValid(x.startedAt))
    .map((snap) => {
      const start = snap.startedAt!;
      const end = new Date(start);
      end.setMinutes(end.getMinutes() + 1);

      return {
        start,
        end,
        isProviderSnapshot: true,
        indicatorColor: null,
      };
    })
    .filter((snap) => {
      if (dayjs.utc(selected).isSame(dayjs.utc(snap.start), 'day')) {
        hasProviderSnapsInDate = true;
      }

      const dateStr = dayjs.utc(snap.start).format(DATE_FORMAT);
      if (!seen.has(dateStr)) {
        seen.add(dateStr);
        return true;
      }

      return false;
    });

  const events: PointInTimeEvent[] = [...eonSnapsEvents, ...otherSnapsEvents];

  return (
    <Stack
      direction='column'
      justifyContent='space-between'
      height='100%'
      sx={{ margin: '0 auto' }}
      flexGrow='1'
      overflow='hidden'
    >
      <Stack flexGrow='1' overflow='scroll'>
        {convertToEonSnapshotEnabled && (
          <OtherSnapshotsToggle
            resource={entity}
            showOtherSnapshots={showOtherSnapshots}
            setShowOtherSnapshots={(value) => {
              setSelected(undefined);
              setShowOtherSnapshots(value);
            }}
          />
        )}
        <PointsInTimeCalendar
          isLoading={isLoading}
          onDateRangeChanged={(start, end) => {
            setStartEndDates({ start, end });
            setSelected(undefined);
          }}
          events={events}
          onDateSelected={(selected) => {
            if (selected) {
              setSnapshotDataOpen(true);
            }
            setSelected(selected);
          }}
          selectedDate={selected}
        />
      </Stack>
      {snapshotDataOpen && selected ? (
        <RestoreSnapshotDrawer
          vaults={vaults}
          eonSnapshots={snapshots}
          hasProviderSnapsInDate={hasProviderSnapsInDate}
          selectedDate={selected}
          entity={entity}
          onClose={() => {
            setSelected(undefined);
            setSnapshotDataOpen(false);
          }}
        />
      ) : (
        <ConvertToEonSnapshotsBar
          numOfOtherSnapshots={entity.snapshotStorage.nonEonSnapshotCount || 0}
          cloudProvider={entity.cloudProvider}
          resource={entity}
        />
      )}
    </Stack>
  );
};
