import type { BackupSchedule, Condition } from '@repo/api-gw-sdk';

import type { Step } from '@/components/wizard/Step';

import { BackupPolicyConditionEditor } from './BackupPolicyConditionEditor';
import { BackupPolicyNameSelection } from './BackupPolicyNameSelection';
import { BackupPolicyResourcesList } from './BackupPolicyResourcesList';
import { BackupPolicySettings } from './BackupPolicySettings';

export interface BackupPolicyEditState {
  id: string;
  name: string;
  condition?: Condition;
  schedules?: BackupSchedule[];
  include?: string[];
  exclude?: string[];
  basic: boolean;
}

export const EditBackupPolicyFlow: Step<BackupPolicyEditState> = {
  name: 'Name Selection',
  Component: BackupPolicyNameSelection,
  next: [
    {
      name: 'Condition',
      Component: BackupPolicyConditionEditor,
      next: [
        {
          name: 'Resource list',
          Component: BackupPolicyResourcesList,
          next: [
            {
              name: 'Frequency and retention',
              Component: BackupPolicySettings,
            },
          ],
        },
      ],
    },
  ],
};
