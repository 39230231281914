import { List, ListItemButton, Radio, Typography } from '@mui/material';
import type {
  Account,
  FileSnapshot,
  Provider,
  Snapshot,
} from '@repo/api-gw-sdk';
import { type Dispatch, type SetStateAction } from 'react';

import { Loader } from '@/components/layout/loading';
import { CircleImage } from '@/components/shared/circleImage';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { CloudProviders } from '@/data/inventory/data';

import { SnapshotToolbar } from './shared';

import { StepContainer } from '../../wizard/StepContainer';

interface RestoreAccountProps {
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  snapshot?: Snapshot;
  fileSnapshot?: FileSnapshot;
  vaultId?: string;
}

type SelectTargetAccountStepProps<T> = StepProps<T & RestoreAccountProps>;

export const SelectTargetAccountStep = <T,>(
  props: SelectTargetAccountStepProps<T>
) => {
  return (
    <StepContainer
      stepperLabels={props.stepperLabels}
      stepperIndex={0}
      onBackClick={props.back}
      canGoNext={() => !!props.wizardState.restoreAccount}
      onNextClick={() => props.setNextStep(props.currentStep.next?.[0])}
      toolbarComponent={
        props.wizardState.snapshot ? (
          <SnapshotToolbar
            pointInTime={props.wizardState.snapshot.pointInTime}
            vaultId={props.wizardState.snapshot.vaultId}
            accessDeniedItemsCount={
              props.wizardState.snapshot.accessDeniedItemsCount
            }
          />
        ) : (
          props.wizardState.fileSnapshot &&
          props.wizardState.vaultId && (
            <SnapshotToolbar
              pointInTime={props.wizardState.fileSnapshot.generatedOn}
              vaultId={props.wizardState.vaultId}
            />
          )
        )
      }
    >
      <SelectTargetAccount
        {...props.wizardState}
        setRestoreAccountState={props.setWizardState}
      />
    </StepContainer>
  );
};

interface SelectTargetAccountProps<T> extends RestoreAccountProps {
  setRestoreAccountState: Dispatch<
    SetStateAction<
      T & {
        restoreAccount?: Account;
      }
    >
  >;
  title?: string;
}

export const SelectTargetAccount = <T,>(props: SelectTargetAccountProps<T>) => {
  const dal = useDAL();
  const { body } = dal.cloudAccounts.restore.list();

  if (!body) {
    return <Loader />;
  }

  const onRestoreAccountClicked = (account: Account) => {
    props.setRestoreAccountState((state) => ({
      ...state,
      restoreAccount: account,
    }));
  };

  return (
    <>
      <Typography component={'span'}>
        {props.title || 'Select an account to restore to:'}
      </Typography>
      <List className='mt-[20px] mb-[10px]'>
        {body.accounts
          .filter(
            (account) =>
              !props.supportedCloudProviders ||
              props.supportedCloudProviders.includes(account.cloudProvider)
          )
          .map((account) => {
            const isChecked = props.restoreAccount?.id === account.id;
            return (
              <ListItemButton
                key={account.id}
                sx={{
                  border: isChecked
                    ? '2px solid var(--mui-palette-primary-main)'
                    : '1px solid var(--mui-palette-divider)',
                  padding: isChecked ? '16px 24px' : '17px 25px',
                  marginBottom: '8px',
                }}
                onClick={() => onRestoreAccountClicked(account)}
                className='flex'
              >
                <Radio className='p-0' checked={isChecked} value={account.id} />
                <Typography className='flex-grow'>
                  <CircleImage
                    key={account.cloudProvider}
                    alt={CloudProviders[account.cloudProvider]?.displayName}
                    src={CloudProviders[account.cloudProvider]?.logo}
                    className='mr-[8px] justify-middle align-middle'
                  />
                  {account.name}
                </Typography>
                {account.providerAccountId !== account.name && (
                  <Typography component={'span'}>
                    {account.providerAccountId}
                  </Typography>
                )}
              </ListItemButton>
            );
          })}
      </List>
    </>
  );
};
