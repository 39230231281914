import {
  Box,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';

import { RegionSelect } from '@/components/regions/regionSelect';
import { TagsSection } from '@/components/tags/tagsSection';
import type { StepProps } from '@/components/wizard/StepProps';

import { EonTagRemark, SnapshotToolbar } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type {
  ConvertAMIBaseState,
  ConvertToAMIState,
} from '../convertToAMIWizard';

export const ImageConfiguration = (props: StepProps<ConvertToAMIState>) => {
  const getValue = useCallback(
    <T extends keyof ConvertAMIBaseState>(value: T): ConvertAMIBaseState[T] => {
      // keeping undefined for cases where the user selected empty option intentionally.
      return value in props.wizardState.crossStepsState
        ? props.wizardState.crossStepsState[value]
        : props.wizardState.initialState[value];
    },
    [props.wizardState.crossStepsState, props.wizardState.initialState]
  );

  const [scopedState, setScopedState] = useState<ConvertAMIBaseState>({
    regionName: getValue('regionName'),
    tags: getValue('tags'),
    keepOriginalTags: getValue('keepOriginalTags'),
  });

  const onFieldChange = <T extends keyof ConvertAMIBaseState>(
    field: T,
    value: ConvertAMIBaseState[T],
    isUserChange?: boolean
  ) => {
    setScopedState((state) => ({
      ...state,
      [field]: value,
    }));
    if (isUserChange) {
      props.setWizardState((state) => ({
        ...state,
        crossStepsState: { ...state.crossStepsState, [field]: value },
      }));
    }
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={1}
      nextButtonText='Next'
      onBackClick={props.back}
      canGoNext={() => {
        if (!scopedState.regionName || !props.wizardState.name) {
          return false;
        }

        return true;
      }}
      onNextClick={() => {
        props.setWizardState((state) => ({
          ...state,
          crossStepsState: { ...state.crossStepsState, ...scopedState },
        }));
        props.setNextStep(props.currentStep.next?.[0]);
      }}
      toolbarComponent={
        <SnapshotToolbar
          pointInTime={props.wizardState.snapshot.pointInTime}
          vaultId={props.wizardState.snapshot.vaultId}
          accessDeniedItemsCount={
            props.wizardState.snapshot.accessDeniedItemsCount
          }
        />
      }
    >
      <Box className='my-[24px] mx-[40px]'>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <RegionSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={scopedState.regionName}
            onChange={(regionName, isUserChange) =>
              onFieldChange('regionName', regionName, isUserChange)
            }
          />
          <FormControl size='small' className='flex-1'>
            <FormLabel>Image name</FormLabel>
            <TextField
              data-testid='image-name-field'
              size='small'
              value={props.wizardState.name || ''}
              onChange={(event) =>
                props.setWizardState((state) => ({
                  ...state,
                  name: event.target.value,
                }))
              }
            />
          </FormControl>
        </Stack>
      </Box>
      <Box className='my-[24px] mx-[40px]'>
        <Stack
          direction='row'
          alignItems='center'
          gap='36px'
          className='mt-[20px]'
        >
          <FormControl size='small' className='flex-1'>
            <FormLabel>Image description (optional)</FormLabel>
            <TextField
              data-testid='image-description-field'
              size='small'
              value={props.wizardState.description || ''}
              onChange={(event) =>
                props.setWizardState((state) => ({
                  ...state,
                  description: event.target.value,
                }))
              }
            />
          </FormControl>
        </Stack>
      </Box>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={props.wizardState.initialState.tags || {}}
          tags={scopedState.tags || {}}
          keepOriginalTags={scopedState.keepOriginalTags}
          setKeepOriginalTags={(value) =>
            onFieldChange('keepOriginalTags', value, true)
          }
          onChange={(tags) => onFieldChange('tags', tags, true)}
        />
      </CardContent>
    </StepContainer>
  );
};
