import {
  Provider,
  type Account,
  type InventoryResource,
  type Snapshot,
  type InventorySnapshotVolume,
} from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import type { RestoreGenericStorageProps } from './bucketSelection';
import { EksNamespaceConfiguration } from './steps/eksNamespaceConfiguration';
import { EksVolumesConfiguration } from './steps/eksVolumesConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { ResourcePanelTabs } from '../panels/instancePanel';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreEKSNamespaceState extends RestoreGenericStorageProps {
  resource: InventoryResource;
  snapshot: Snapshot;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  volumes: InventorySnapshotVolume[];
  prefix?: string;
}

const restoreEKSNamespaceFlow: Step<RestoreEKSNamespaceState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'EksNamespaceConfiguration',
      Component: EksNamespaceConfiguration,
      next: [
        {
          name: 'EksVolumesConfiguration',
          Component: EksVolumesConfiguration,
        },
      ],
    },
  ],
};

export const RestoreEksNamespaceWizard = ({
  resource,
  snapshot,
  originTab,
}: {
  resource: InventoryResource;
  snapshot: Snapshot;
  originTab: ResourcePanelTabs;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resource.id,
        onClose: () => setIsOpen(false),
        initialTab: originTab,
      },
    });

    return true;
  };

  return (
    <Wizard<RestoreEKSNamespaceState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreEKSNamespaceFlow]}
      initialState={{
        snapshot,
        resource,
        supportedCloudProviders: [Provider.Aws],
        restoreMode: 'select',
        S3: {},
        StorageAccount: {},
        region: resource.region,
        volumes: [], // in eks namespace we don't get the volumes from the list snapshots
      }}
      title='Restore EKS Namespace'
      stepperLabels={[
        'Restore Account',
        'Namespace Configuration',
        'Volumes Configuration',
      ]}
    />
  );
};
