import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import { useEffect } from 'react';

import { useDAL } from '@/data/dal';

import { EmptySelect } from '../regions/emptySelect';
import SelectPlaceholder from '../selectPlaceholder';

const title = 'Instance type';

export const InstanceTypeSelect = ({
  accountId,
  regionName,
  instanceType,
  onChange,
  subnetId,
}: {
  accountId?: string;
  regionName?: string;
  instanceType?: string;
  subnetId?: string;
  onChange: (instanceType?: string, isUserChange?: boolean) => void;
}) => {
  if (!accountId || !regionName || !subnetId) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerInstanceTypeSelect
      subnetId={subnetId}
      accountId={accountId}
      regionName={regionName}
      instanceType={instanceType}
      onChange={onChange}
    />
  );
};

const InnerInstanceTypeSelect = ({
  subnetId,
  accountId,
  regionName,
  instanceType,
  onChange,
}: {
  accountId: string;
  regionName: string;
  subnetId: string;
  instanceType?: string;
  onChange: (instanceType?: string, isUserChange?: boolean) => void;
}) => {
  const dal = useDAL();
  const { body, isLoading } = dal.restore.instanceTypes.list(
    accountId,
    regionName,
    subnetId
  );

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (instanceType && !body?.instanceTypes?.includes(instanceType)) {
      onChange(undefined);
    }
  }, [body?.instanceTypes, isLoading, onChange, instanceType]);

  if (!body?.instanceTypes?.length) {
    return <EmptySelect title={title} />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        data-testid='instanceTypeSelect'
        size='small'
        value={instanceType || ''}
        onChange={(event) => onChange(event.target.value, !!event)}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
      >
        {body.instanceTypes.map((instanceType, index) => (
          <MenuItem
            data-testid={`instanceTypeOption-${index}`}
            key={instanceType}
            value={instanceType}
          >
            {instanceType}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
