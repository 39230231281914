import { Stack, Typography } from '@mui/material';

import OptionMenu from '@/@core/components/option-menu';
import { useDAL } from '@/data/dal';

interface SecurityScanMutedBarProps {
  resourceId: string;
  onEntityChange?: () => Promise<void>;
}

export const SecurityScanMutedBar = (props: SecurityScanMutedBarProps) => {
  const { resourceId, onEntityChange } = props;
  const dal = useDAL();

  const triggerUnmuteAlerts = () => {
    void dal.security.alerts.unmute(resourceId).then(() => {
      void onEntityChange?.();
    });
  };

  return (
    <Stack
      direction='row'
      gap='12px'
      alignItems='center'
      justifyContent='center'
    >
      <Typography>Ransomware detection muted</Typography>
      <OptionMenu
        icon='material-symbols-more-horiz'
        iconClassName='text-textPrimary'
        options={['Unmute alerts']}
        onOptionSelected={triggerUnmuteAlerts}
      />
    </Stack>
  );
};
