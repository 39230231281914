import { Box } from '@mui/material';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/accordion';

interface MultiAccordionsItemProps {
  isMulti?: boolean;
  isSelected: boolean;
  onSelect: () => void;
  Summary: React.ReactNode;
  Details: React.ReactNode;
}

export const MultiAccordionsItem = (props: MultiAccordionsItemProps) => {
  const { isSelected, isMulti, onSelect, Summary, Details } = props;
  return isMulti ? (
    <Accordion
      sx={{
        paddingY: '12px',
        paddingLeft: '40px',
      }}
      expanded={isSelected}
    >
      <AccordionSummary
        sx={{
          '& .MuiTypography-root': { fontWeight: '400 !important' },
        }}
        onClick={onSelect}
      >
        {Summary}
      </AccordionSummary>
      <AccordionDetails>{Details}</AccordionDetails>
    </Accordion>
  ) : (
    <Box padding='24px 40px'>
      <Box paddingBottom='24px'>{Summary}</Box>
      {Details}
    </Box>
  );
};
