import { useState } from 'react';

import {
  InstancePanel,
  type ResourcePanelTabs,
} from '@/components/panels/instancePanel';
import { useFields } from '@/components/table/hooks';
import { useDAL } from '@/data/dal';

import ExcludeFromBackupDialog from './excludeFromBackupDialog';
import InventoryBulkPolicyAssignmentDialog from './inventoryBulkPolicyAssignmentDialog';
import useInstanceActions from './inventoryInstanceActions';
import { inventoryItemFields } from './inventoryItemFields';
import TakeSnapshotDialog from './takeSnapshotDialog';

export const InventoryInstancePanel = ({
  id,
  onClose,
  onRowContentChange,
  initialTab,
}: {
  id: string;
  initialTab?: ResourcePanelTabs;
  onClose: () => void;
  onRowContentChange?: () => Promise<void>;
}) => {
  const [assignBackupPolicyDialogOpen, setAssignBackupPolicyDialogOpen] =
    useState(false);
  const [excludeFromBackupDialogOpen, setExcludeFromBackupDialogOpen] =
    useState(false);
  const [takeSnapshotDialogOpen, setTakeSnapshotDialogOpen] = useState(false);
  const dal = useDAL();

  const { isLoading, body, mutate } = dal.inventory.get(id);

  const onEntityChange = async () => {
    await onRowContentChange?.();
    await mutate();
  };

  const fields = useFields(inventoryItemFields);
  const actions = useInstanceActions(
    body?.resource,
    onEntityChange,
    setAssignBackupPolicyDialogOpen,
    setExcludeFromBackupDialogOpen,
    setTakeSnapshotDialogOpen
  );

  return (
    <>
      <InstancePanel
        entity={body?.resource}
        onClose={onClose}
        fields={fields}
        actions={actions}
        isLoading={isLoading}
        onEntityChange={onEntityChange}
        initialTab={initialTab}
      />
      {assignBackupPolicyDialogOpen && (
        <InventoryBulkPolicyAssignmentDialog
          resourceIds={body?.resource.id ? [body?.resource.id] : []}
          onClose={() => {
            setAssignBackupPolicyDialogOpen(false);
            void onEntityChange();
          }}
        />
      )}
      <ExcludeFromBackupDialog
        isOpen={excludeFromBackupDialogOpen}
        entities={body?.resource ? [body?.resource] : []}
        onClose={() => {
          setExcludeFromBackupDialogOpen(false);
          void onEntityChange();
        }}
      />
      {takeSnapshotDialogOpen && (
        <TakeSnapshotDialog
          entities={body?.resource ? [body?.resource] : []}
          onClose={() => {
            setTakeSnapshotDialogOpen(false);
            void onEntityChange();
          }}
        />
      )}
    </>
  );
};
