import {
  apps,
  accountId,
  dataClasses,
  environment,
  resourceType,
  sourceRegion,
  subnets,
  vpc,
  tags,
  cloudProvider,
  resourceGroupName,
} from '@/components/queryBuilder/properties';
import { type FilterProperty } from '@/types/advanceFilter';

export const supportedControlProperties: FilterProperty[][] = [
  [resourceType, cloudProvider, dataClasses, environment, apps],
  [accountId, sourceRegion, vpc, subnets, resourceGroupName],
  [tags],
];
