import { useState } from 'react';

import { PanelWrapper } from '@/components/panels/panelWrapper';
import { useWorkspace } from '@/contexts/useWorkspace';

import type { Step } from './Step';

interface WizardProps<T> {
  onFinish: (state: T) => boolean;
  onAbort: (origin?: 'back') => void;
  title: string;
  initialState: T;
  flow: Step<T>[];
  stepperLabels?: string[];
}

export const Wizard = <T,>(props: WizardProps<T>) => {
  const [steps, setSteps] = useState(props.flow);
  const currentStep = steps[steps.length - 1];
  const [wizardState, setWizardState] = useState<T>(props.initialState);
  const { rightPanel, snackbar } = useWorkspace();
  const [finishInProgress, setFinishInProgress] = useState(false);
  const { setIsOpen } = rightPanel;

  const back = () => {
    if (steps.length <= 1) {
      props.onAbort('back');
    } else {
      setSteps(steps.slice(0, steps.length - 1));
    }
  };

  const next = (step: Step<T> | undefined) => {
    if (step) {
      setSteps([...steps, step]);
    }
  };

  const finish = () => {
    if (finishInProgress) {
      return;
    }

    setFinishInProgress(true);
    if (props.onFinish(wizardState)) {
      setIsOpen(false);
    } else {
      setFinishInProgress(false);
    }
  };

  return (
    <PanelWrapper
      testIdPrefix='wizard'
      header={{
        hideBorder: true,
        title: props.title,
        onBack: back,
        onClose: props.onAbort,
      }}
    >
      <currentStep.Component
        stepperLabels={props.stepperLabels ?? []}
        currentStep={currentStep}
        back={back}
        abort={props.onAbort}
        wizardState={wizardState}
        setWizardState={setWizardState}
        finish={finish}
        setNextStep={next}
        onSuccess={(message: string) => {
          snackbar.showMessage(message);
        }}
      />
    </PanelWrapper>
  );
};
