import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import type { InventoryResource, Snapshot } from '@repo/api-gw-sdk';
import { ResourceType, SnapshotStatus } from '@repo/api-gw-sdk';
import { useMemo } from 'react';

import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import { volumePresets } from '@/components/volumes/volumeSettings';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';
import { DATE_TIME_FORMAT, dayjs } from '@/utils/dayjs';
import { fileSizeFormatter } from '@/utils/fileSizeFormatter';

import { Panels } from '../../panels';

export const DatabaseSection = ({
  resource,
}: {
  resource: InventoryResource;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  const { isAuthorizedResource } = useRoles();
  const dal = useDAL();
  const { body } = dal.inventory.snapshots.list(resource.id, 0, 100, [
    SnapshotStatus.Completed,
  ]);
  const { data: vaults } = useBackupVaults();

  const latestSnapshot = useMemo(
    () =>
      (body?.data || []).reduce<Snapshot | undefined>((agg, x) => {
        if (
          x.status === SnapshotStatus.Completed &&
          isDateValid(x.pointInTime)
        ) {
          if (
            !agg?.pointInTime ||
            agg.pointInTime.getTime() < x.pointInTime.getTime()
          ) {
            return x;
          }
        }

        return agg;
      }, undefined),
    [body?.data]
  );

  const vault =
    latestSnapshot && vaults?.find((v) => v.id === latestSnapshot.vaultId);

  return (
    <>
      {vault && (
        <Stack
          className='w-full px-[40px]'
          direction={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          {isDateValid(latestSnapshot.pointInTime) && (
            <Stack direction={'row'} alignItems={'center'}>
              <SnapshotIndicator backgroundColor={vault.backgroundColor!} />
              <Typography
                className='ml-[12px]'
                sx={{ fontWeight: '400 !important' }}
              >
                {`Last backed up on ${dayjs.utc(latestSnapshot.pointInTime).format(DATE_TIME_FORMAT)} UTC`}
              </Typography>
            </Stack>
          )}
          <Button
            disabled={
              !isAuthorizedResource('create:restore_resource', resource)
            }
            variant='outlined'
            className='my-[16px]'
            onClick={() =>
              setComponent({
                panel:
                  resource.resourceType === ResourceType.AtlasMongodbCluster
                    ? Panels.RestoreMongoAtlasWizard
                    : Panels.RestoreRDSWizard,
                props: {
                  resource,
                  snapshot: latestSnapshot,
                  sourceRegion: vaults.find(
                    (x) => x.id === latestSnapshot.vaultId
                  )?.region,
                  originTab: 'overview',
                },
              })
            }
          >
            <i
              className={`material-symbols-settings-backup-restore-rounded text-[18px] mr-[8px] text-primary align-middle`}
            />
            Restore
          </Button>
        </Stack>
      )}
      <Table
        className='w-full'
        sx={{
          '& .props-col': {
            verticalAlign: 'top',
            width: '220px',
            paddingLeft: '40px',
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell
              className='props-col'
              sx={{
                borderTop: '1px solid var(--mui-palette-TableCell-border)',
              }}
            >
              Database name
            </TableCell>
            <TableCell
              sx={{
                borderTop: '1px solid var(--mui-palette-TableCell-border)',
              }}
            >
              {resource.resourceProperties?.awsRds?.databaseName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Cluster</TableCell>
            <TableCell>
              {resource.resourceProperties?.awsRds?.clusterId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Instance class</TableCell>
            <TableCell>
              {resource.resourceProperties?.awsRds?.instanceClass}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Multi AZ</TableCell>
            <TableCell className='capitalize'>
              {Boolean(
                resource.resourceProperties?.awsRds?.isMultiAZ
              ).toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Publicly accessible</TableCell>
            <TableCell className='capitalize'>
              {Boolean(
                resource.resourceProperties?.awsRds?.isPubliclyAccessible
              ).toString()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Type</TableCell>
            <TableCell>
              {resource.resourceProperties?.awsRds?.storageSettings?.type &&
                volumePresets[
                  resource.resourceProperties?.awsRds?.storageSettings?.type
                ]?.displayName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>Size</TableCell>
            <TableCell>
              {resource.resourceProperties?.awsRds?.sizeBytes !== undefined &&
                fileSizeFormatter(
                  resource.resourceProperties?.awsRds?.sizeBytes
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className='props-col'>IOPS</TableCell>
            <TableCell>
              {resource.resourceProperties?.awsRds?.storageSettings?.iops}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className='props-col'
              sx={{
                borderBottomWidth: '0',
              }}
            >
              Throughput
            </TableCell>
            <TableCell
              sx={{
                borderBottomWidth: '0',
              }}
            >{`${resource.resourceProperties?.awsRds?.storageSettings?.throughput} MB/s`}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
