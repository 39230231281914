import { Stack, styled, type StackProps } from '@mui/material';

export const ConvertToEonDetailedColumn = styled(Stack)(() => ({
  width: '228px',
  gap: '18px',
}));

export const ConvertToEonDetailedRow = (props: StackProps) => {
  return (
    <Stack direction='row' justifyItems='start' gap='40px'>
      {props.children}
    </Stack>
  );
};
