import type { Account, InventoryResource, Snapshot } from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import { useWorkspace } from '@/contexts/useWorkspace';

import { RDSConfiguration } from './steps/rdsConfiguration';
import { SelectTargetAccountStep } from './steps/selectTargetAccount';

import type { Encryption } from '../encryption/encryptionComponent';
import type { ResourcePanelTabs } from '../panels/instancePanel';
import { Panels } from '../panels/panels';
import type { Step } from '../wizard/Step';
import { Wizard } from '../wizard/Wizard';

export interface RestoreRDSState {
  resourceId: string;
  snapshot: Snapshot;
  resourceName?: string;
  supportedCloudProviders: Provider[];
  restoreAccount?: Account;
  regionName?: string;
  securityGroupIds?: string[];
  rdsSubnetGroupName?: string;
  vpc?: string;
  tags?: Record<string, string>;
  keepOriginalTags?: boolean;
  encryption?: Encryption;
  initialRegionName: string;
}

const restoreRDSFlow: Step<RestoreRDSState> = {
  name: 'SelectTargetAccountStep',
  Component: SelectTargetAccountStep,
  next: [
    {
      name: 'RDSConfiguration',
      Component: RDSConfiguration,
    },
  ],
};

export const RestoreRDSWizard = ({
  resource,
  snapshot,
  sourceRegion,
  originTab,
}: {
  resource: InventoryResource;
  snapshot: Snapshot;
  sourceRegion?: string;
  originTab: ResourcePanelTabs;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent, setIsOpen } = rightPanel;

  const close = () => {
    setComponent({
      panel: Panels.InventoryInstance,
      props: {
        id: resource.id,
        onClose: () => setIsOpen(false),
        initialTab: originTab,
      },
    });

    return true;
  };

  return (
    <Wizard<RestoreRDSState>
      onAbort={(origin) => (origin === 'back' ? close() : setIsOpen(false))}
      onFinish={close}
      flow={[restoreRDSFlow]}
      initialState={{
        initialRegionName: sourceRegion || 'us-east-1',
        snapshot,
        resourceId: resource.id,
        supportedCloudProviders: [Provider.Aws],
        keepOriginalTags: true,
      }}
      title={`Restore RDS`}
      stepperLabels={['Restore Account', 'Configuration']}
    />
  );
};
