import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Divider,
  TextField,
  Stack,
  Typography,
  Radio,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Loader } from '@/components/layout/loading';

import { useCreateAccount, type AccountType } from './useCreateAccount';

export const CreateAWSAccountPanel = ({
  accountType,
  onCreated,
  setLoading,
}: {
  accountType: AccountType;
  onCreated: () => void;
  setLoading: (loading: boolean) => void;
}) => {
  const {
    accountId: awsAccountId,
    setAccountId,
    roleName: accountRoleName,
    setRoleName: setAccountRoleName,
    orgRoleName,
    setOrgRoleName,
    organizationOUID,
    setOrganizationOUID,
    validationInProgress,
    isCreated,
    isCreationInProgress,
    hasError,
    retry,
    createAccount,
    validateAccount,
    validateOrganization,
    connectOrganization,
  } = useCreateAccount(accountType, onCreated);
  const [connectionType, setConnectionType] = useState<
    'account' | 'organization'
  >('account');

  const roleName =
    connectionType === 'organization' ? orgRoleName : accountRoleName;
  const setRoleName =
    connectionType === 'organization' ? setOrgRoleName : setAccountRoleName;

  const validate =
    connectionType === 'organization' ? validateOrganization : validateAccount;

  useEffect(() => {
    setLoading(validationInProgress || isCreationInProgress || hasError);
  }, [hasError, isCreationInProgress, setLoading, validationInProgress]);

  const accountId =
    connectionType === 'organization'
      ? 'AWS management account ID'
      : 'AWS account ID';

  const createdText =
    connectionType === 'organization'
      ? 'Organization connected'
      : 'Account created';

  const doneOnClick = () => {
    if (connectionType === 'organization') {
      void connectOrganization();
    } else {
      void createAccount();
    }
  };

  return (
    <Box className='px-[20px]'>
      <>
        {accountType === 'source' && (
          <Stack direction='column' gap='16px'>
            <Typography marginBottom='8px' variant='body2'>
              What do you want to connect?
            </Typography>
            <Stack
              className='cursor-pointer'
              direction='row'
              alignItems='center'
              gap='12px'
              onClick={() => setConnectionType('account')}
            >
              <Radio
                data-testid='full-instance-radio'
                size='small'
                checked={connectionType === 'account'}
              />
              <Typography variant='body2'>Account</Typography>
            </Stack>
            <Stack
              className='cursor-pointer'
              direction='row'
              alignItems='center'
              gap='12px'
              onClick={() => setConnectionType('organization')}
            >
              <Radio
                data-testid='specific-vol-radio'
                size='small'
                checked={connectionType === 'organization'}
              />
              <Typography variant='body2'>Organization</Typography>
            </Stack>
          </Stack>
        )}
        {connectionType === 'account' && (
          <Box className='my-[15px]'>
            Before we begin,{' '}
            <b>
              make sure you are logged in to that account in the current browser
              and can create roles
            </b>
          </Box>
        )}
        <Box className='my-[15px]'>
          We&apos;ll take you straight to the AWS console to set Eon&apos;s
          permissions. Everything is ready for you, just jump between the steps,
          confirm, and get back to us.
        </Box>
      </>
      <Box className='flex flex-col justify-center my-[30px] gap-[8px]'>
        <FormControl size='small' className='mb-[12px]'>
          <FormLabel>{accountId}</FormLabel>
          <TextField
            size='medium'
            inputProps={{
              sx: { height: '36px' },
              maxLength: 12,
              'data-testid': 'account-id',
            }}
            disabled={validationInProgress || isCreationInProgress || hasError}
            value={awsAccountId}
            onChange={(event) => setAccountId(event.target.value)}
            onKeyDown={(event) => event.key === 'Enter' && void validate()}
          />
        </FormControl>
        {connectionType === 'organization' && (
          <FormControl size='small' className='mb-[12px]'>
            <FormLabel>Organization OU ID</FormLabel>
            <TextField
              size='medium'
              inputProps={{ style: { height: '36px' } }}
              value={organizationOUID}
              disabled={
                validationInProgress || isCreationInProgress || hasError
              }
              onChange={(event) => {
                setOrganizationOUID(event.target.value);
              }}
              onKeyDown={(event) => event.key === 'Enter' && void validate()}
            />
          </FormControl>
        )}
        <FormControl size='small' className='mb-[12px]'>
          <FormLabel>Role name</FormLabel>
          <TextField
            size='medium'
            inputProps={{ style: { height: '36px' } }}
            value={roleName}
            disabled={validationInProgress || isCreationInProgress || hasError}
            onChange={(event) => setRoleName(event.target.value)}
            onKeyDown={(event) => event.key === 'Enter' && void validate()}
          />
        </FormControl>
        {!validationInProgress &&
          !isCreationInProgress &&
          !isCreated &&
          !hasError && (
            <Button
              variant='contained'
              className='px-[20px] mt-[20px] w-auto'
              sx={{ height: 48 }}
              data-testid='open-aws-console'
              disabled={
                !awsAccountId ||
                !roleName ||
                (connectionType === 'organization' && !organizationOUID)
              }
              onClick={() => void validate()}
            >
              Open AWS Console{' '}
              <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]' />
            </Button>
          )}
        {(validationInProgress || isCreationInProgress) && (
          <Box
            className='flex items-center gap-[8px] ml-[20px] justify-between'
            sx={{ color: 'var(--mui-palette-background-lightGray)' }}
          >
            <Stack alignItems='center' direction='row' gap='12px'>
              <Loader sx={{ width: 20 }} />
              {validationInProgress && <>Waiting for permissions...</>}
              {isCreationInProgress && <>Trying to connect...</>}
            </Stack>
            {!isCreationInProgress && (
              <>
                <Button
                  variant='contained'
                  data-testid='aws-console-done'
                  onClick={doneOnClick}
                >
                  I&apos;m Done
                </Button>
              </>
            )}
          </Box>
        )}
        {isCreated && (
          <Box
            className='flex items-center gap-[4px] ml-[20px] h-[48px]'
            sx={{ color: 'var(--mui-palette-success-main)' }}
          >
            <i className='material-symbols-check w-[20px] h-[20px]' />{' '}
            {createdText}
          </Box>
        )}
        {hasError && (
          <Box
            className='flex items-center gap-[4px] ml-[20px] h-[48px]'
            sx={{ color: 'var(--mui-palette-error-main)' }}
          >
            <i className='material-symbols-close-rounded w-[20px] h-[20px]' />{' '}
            Something went wrong
            <Divider
              orientation='vertical'
              flexItem
              sx={{
                borderColor: 'var(--mui-palette-background-lightGray)',
              }}
              className='mx-[6px] my-auto h-[20px]'
            />
            <Button variant='contained' onClick={retry}>
              Try Again
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
