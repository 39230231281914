import { capitalize } from '@mui/material';
import { Provider, State } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';

export type AccountType = 'source' | 'vault' | 'restore';

const URLS: Record<AccountType, string> = {
  source:
    'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/source-account.yml',
  vault:
    'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/vault-account.yml',
  restore:
    'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/restore-account.yml',
};

const awsOrganizationURL =
  'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/aws-organization.yml';
const awsScanOrganizationAccountsURL =
  'https://eon-public-b2b628cc-1d96-4fda-8dae-c3b1ad3ea03b.s3.amazonaws.com/testing/aws-scan-organization-account-role.yml';

const BASE_URL =
  'https://console.aws.amazon.com/cloudformation/home?#/stacks/create/review?';

const popup = async ({
  url,
  title,
  width,
  height,
}: {
  url: string;
  title: string;
  width: number;
  height: number;
}) => {
  const { screen } = window;
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;

  const newWindow = window.open(
    url,
    title,
    `scrollbars=yes,width=${width},height=${height},top=${top},left=${left},screenX=${left},screenY=${top}`
  );
  newWindow?.focus();

  await new Promise<void>((resolve) => {
    const interval = setInterval(() => {
      if (newWindow?.closed) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });
};

export const useCreateAccount = (
  accountType: AccountType,
  onDone: (accountId: string) => void
) => {
  const { orgWithoutStackSet } = useFeatureFlags();
  const dal = useDAL();
  const { user } = useUser();
  const { serviceAccountId } = useEnvironment();
  const [accountId, setAccountId] = useState('');
  const [roleName, setRoleName] = useState(
    `Eon${capitalize(accountType)}AccountRole`
  );
  const [orgRoleName, setOrgRoleName] = useState(`EonOrganizationAccountRole`);
  const [organizationOUID, setOrganizationOUID] = useState(``);
  const [validationInProgress, setValidationInProgress] = useState(false);
  const [isCreationInProgress, setIsCreationInProgress] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const orgURL = orgWithoutStackSet
    ? awsScanOrganizationAccountsURL
    : awsOrganizationURL;

  const connectOrganization = async () => {
    setValidationInProgress(false);
    setIsCreationInProgress(true);
    const { id, success } = await dal.cloudAccounts.organization
      .connect({
        authParams: {
          roleArn: `arn:aws:iam::${accountId}:role/${orgRoleName}`,
        },
        name: accountId,
        state: State.Active,
        organizationOUId: organizationOUID,
      })
      .then((res) => ({ success: true, id: res.awsOrganization.id }))
      .catch(() => ({ success: false, id: '' }));
    setIsCreationInProgress(false);
    if (success) {
      setIsCreated(true);
      setTimeout(() => onDone(id), 1000);
    } else {
      setHasError(true);
    }
  };

  const createAccount = async () => {
    setValidationInProgress(false);
    setIsCreationInProgress(true);
    const { id, success } = await dal.cloudAccounts[accountType]
      .create({
        cloudProvider: Provider.Aws,
        role: `arn:aws:iam::${accountId}:role/${roleName}`,
        name: accountId,
        regions:
          accountType === 'restore' || accountType === 'vault'
            ? ['us-east-1']
            : undefined,
      })
      .then((res) => ({ success: true, id: res.id }))
      .catch(() => ({ success: false, id: '' }));
    setIsCreationInProgress(false);
    if (success) {
      setIsCreated(true);
      setTimeout(() => onDone(id), 1000);
    } else {
      setHasError(true);
    }
  };

  const validateAccount = async () => {
    if (!accountId || !roleName || !user) {
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('templateURL', URLS[accountType]);
    searchParams.append('stackName', `EonBackup${capitalize(accountType)}`);
    searchParams.append('param_EonAccountId', user.eonAccount.id);
    searchParams.append('param_RoleName', roleName);
    if (accountType === 'source') {
      const body = await dal.cloudAccounts.scanning
        .listAsync()
        .catch(() => ({}) as never);
      const scanningAccountId = body?.accounts?.find(
        (x) => x.cloudProvider === Provider.Aws
      )?.providerAccountId;
      if (!scanningAccountId) {
        throw new Error('Cannot find scanning account');
      }
      searchParams.append('param_ScanningAccountId', scanningAccountId);
    }
    if (serviceAccountId) {
      searchParams.append('param_ServiceAccountId', serviceAccountId);
    }

    setValidationInProgress(true);
    await popup({
      url: `${BASE_URL}${searchParams}`,
      title: 'AWSConsole',
      width: window.screen.width * 0.8,
      height: window.screen.height * 0.8,
    });
  };

  const validateOrganization = async () => {
    if (!accountId || !orgRoleName || !user) {
      return;
    }

    const searchParams = new URLSearchParams();
    searchParams.append('templateURL', orgURL);
    searchParams.append('stackName', 'EonOrganization');
    searchParams.append('param_EonAccountId', user.eonAccount.id);
    searchParams.append('param_OrganizationRoleName', orgRoleName);
    searchParams.append('param_OrgId', organizationOUID);
    if (accountType === 'source') {
      const body = await dal.cloudAccounts.scanning
        .listAsync()
        .catch(() => ({}) as never);
      const scanningAccountId = body?.accounts?.[0]?.providerAccountId;
      if (!scanningAccountId) {
        return;
      }
      searchParams.append('param_ScanningAccountId', scanningAccountId);
    }
    if (serviceAccountId) {
      searchParams.append('param_ServiceAccountId', serviceAccountId);
    }

    setValidationInProgress(true);
    await popup({
      url: `${BASE_URL}${searchParams}`,
      title: 'AWSConsole',
      width: window.screen.width * 0.8,
      height: window.screen.height * 0.8,
    });
  };

  return {
    accountId,
    setAccountId,
    roleName,
    orgRoleName,
    setRoleName,
    setOrgRoleName,
    organizationOUID,
    setOrganizationOUID,
    validationInProgress,
    isCreationInProgress,
    isCreated,
    hasError,
    retry: () => setHasError(false),
    validateAccount,
    validateOrganization,
    createAccount,
    connectOrganization,
  };
};
