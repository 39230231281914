import { Box, Divider, Stack, Typography } from '@mui/material';
import React, { type PropsWithChildren, type ReactNode } from 'react';

import { DashedArrow } from '../../shapes/dashedArrow';

interface SnapshotDetailsLayoutProps {
  title: string;
  DotIcon: React.ReactNode;
  showArrow?: boolean;
}

const SnapshotDetailsLayout = ({
  title,
  DotIcon,
  children,
  showArrow,
}: PropsWithChildren<SnapshotDetailsLayoutProps>) => {
  return (
    <Stack alignItems='start' direction='row' gap='24px'>
      <Stack gap='12px' width='64px' height='100%' justifyItems='center'>
        {DotIcon}
        {showArrow && (
          <Box flexGrow={1}>
            <DashedArrow />
          </Box>
        )}
      </Stack>
      <Stack flexGrow={1} gap='24px' width='64px' margin='4px 0'>
        <Typography className='font-semibold'>{title}</Typography>
        {children}
      </Stack>
    </Stack>
  );
};

interface LifetimePoint {
  dot: ReactNode;
  details: ReactNode;
  title: string;
}

interface ConvertLifetimeLayoutProps {
  source: LifetimePoint;
  target: LifetimePoint;
  description: ReactNode;
}

export default function ConvertLifetimeLayout(
  props: ConvertLifetimeLayoutProps
) {
  const { source, target, description } = props;
  return (
    <Stack paddingTop='24px' gap='24px'>
      {description}
      <Divider />
      <Stack paddingX='40px' gap={'24px'}>
        <SnapshotDetailsLayout
          title={source.title}
          DotIcon={source.dot}
          showArrow
        >
          <Stack paddingBottom='24px'>{source.details}</Stack>
        </SnapshotDetailsLayout>
        <SnapshotDetailsLayout title={target.title} DotIcon={target.dot}>
          {target.details}
        </SnapshotDetailsLayout>
      </Stack>
    </Stack>
  );
}
