import { Divider, MenuItem, Stack, Typography } from '@mui/material';
import {
  MinRetentionRuleFrequencyEnum,
  type BackupSchedule,
} from '@repo/api-gw-sdk';
import { useState } from 'react';

import OptionMenu from '@core/components/option-menu';
import type {
  OptionMenuItemType,
  OptionType,
} from '@core/components/option-menu/types';

import {
  Schedule,
  ScheduleContainer,
  ScheduleMenuItem,
  ScheduleSelect,
} from '@/components/schedule/schedule';
import { BackupVaultSelectionDialog } from '@/components/vaults/backupVaultSelectionDialog';
import VaultTag from '@/components/vaults/vaultTag';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import useBackupVaults from '@/data/vaults/useBackupVaults';

import { frequencyMapping } from '../controls/MinimumRetentionEditor';
import { RetentionPopover } from '../controls/retnetionSelectionPopover';

interface BackupPolicyScheduleProps {
  schedule: BackupSchedule;
  deleteAllowed?: boolean;
  onUpdate: (schedule: BackupSchedule) => void;
  onDuplicate: (schedule: BackupSchedule) => void;
  onDelete: (schedule: BackupSchedule) => void;
  basic: boolean;
}

export function BackupPolicySchedule(props: BackupPolicyScheduleProps) {
  const { data: vaults } = useBackupVaults();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { enableHourlyBackup } = useFeatureFlags();

  const updateWindow = (windows: string) => {
    const fragment = frequencyMapping.find((x) => x.windows === windows);
    if (!fragment) {
      return;
    }

    const backupRetention = Math.max(
      props.schedule.backupRetention,
      fragment.minimumRetentionInDays
    );

    props.onUpdate({
      ...props.schedule,
      windows,
      backupRetention,
    });
  };

  const vault = vaults.find((v) => v.id === props.schedule.vaultId);

  return (
    <ScheduleContainer>
      <Schedule direction='row' alignItems='center' gap='16px'>
        <Stack gap='12px'>
          <Stack direction='row' alignItems='center' gap='8px'>
            <Typography>Take</Typography>
            <ScheduleSelect
              MenuProps={{
                disablePortal: true,
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
                sx: {
                  marginTop: '-2px',
                },
              }}
              value={props.schedule.windows}
              onChange={(e) => updateWindow(e.target.value as string)}
              variant='standard'
              data-testid='create-backup-policy-frequency-select'
            >
              {frequencyMapping
                .filter(
                  (freq) =>
                    enableHourlyBackup ||
                    freq.type !== MinRetentionRuleFrequencyEnum.Hourly
                )
                .map((r) => (
                  <ScheduleMenuItem key={r.windows} value={r.windows}>
                    <Typography>{r.text.toLowerCase()}</Typography>
                  </ScheduleMenuItem>
                ))}
            </ScheduleSelect>
            <Typography>and retain for</Typography>
            <RetentionPopover
              onUpdate={props.onUpdate}
              schedule={props.schedule}
            />
            <Typography>on</Typography>
            <ScheduleSelect
              sx={{
                '& .MuiSelect-select:focus': {
                  backgroundColor: 'transparent',
                },
                '& .MuiTypography-root': {
                  color: '#ACB4B6',
                },
              }}
              data-testid='create-backup-policy-vault-select'
              variant='standard'
              value={props.schedule.vaultId || 0}
              open={false}
              native={false}
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              {(!props.schedule.vaultId || !vault) && (
                <MenuItem value={props.schedule.vaultId || 0}>
                  <Typography>Choose vault</Typography>
                </MenuItem>
              )}

              {props.schedule.vaultId && vault && (
                <MenuItem value={props.schedule.vaultId}>
                  <VaultTag
                    vault={vault}
                    sx={{ margin: 0, marginRight: '16px', cursor: 'pointer' }}
                  />
                </MenuItem>
              )}
            </ScheduleSelect>
          </Stack>
          {!props.basic && (
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />
              <Stack direction='row' alignItems='center' gap='12px'>
                <Stack direction='row' alignItems='center' gap='8px'>
                  <Typography>Back up</Typography>
                  <ScheduleSelect
                    MenuProps={{
                      disablePortal: true,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      sx: {
                        marginTop: '-2px',
                      },
                    }}
                    value={props.schedule.windows}
                    onChange={(e) => updateWindow(e.target.value as string)}
                    variant='standard'
                    data-testid='create-backup-policy-frequency-select'
                  >
                    {frequencyMapping.map((r) => (
                      <ScheduleMenuItem key={r.windows} value={r.windows}>
                        <Typography>{r.text.toLowerCase()}</Typography>
                      </ScheduleMenuItem>
                    ))}
                  </ScheduleSelect>
                </Stack>
                <Divider orientation='vertical' flexItem />
                <Stack
                  direction='row'
                  alignItems='center'
                  gap='8px'
                  className='ml-[12px]'
                >
                  <Typography>Schedule for</Typography>
                  <ScheduleSelect
                    MenuProps={{
                      disablePortal: true,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      sx: {
                        marginTop: '-2px',
                      },
                    }}
                    value={props.schedule.windows}
                    onChange={(e) => updateWindow(e.target.value as string)}
                    variant='standard'
                    data-testid='create-backup-policy-frequency-select'
                  >
                    {frequencyMapping.map((r) => (
                      <ScheduleMenuItem key={r.windows} value={r.windows}>
                        <Typography>{r.text.toLowerCase()}</Typography>
                      </ScheduleMenuItem>
                    ))}
                  </ScheduleSelect>
                  <Typography>and start within</Typography>
                  <ScheduleSelect
                    MenuProps={{
                      disablePortal: true,
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      sx: {
                        marginTop: '-2px',
                      },
                    }}
                    value={props.schedule.windows}
                    onChange={(e) => updateWindow(e.target.value as string)}
                    variant='standard'
                    data-testid='create-backup-policy-frequency-select'
                  >
                    {frequencyMapping.map((r) => (
                      <ScheduleMenuItem key={r.windows} value={r.windows}>
                        <Typography>{r.text.toLowerCase()}</Typography>
                      </ScheduleMenuItem>
                    ))}
                  </ScheduleSelect>
                </Stack>
              </Stack>
            </>
          )}
        </Stack>
        <OptionMenu
          iconClassName='text-textPrimary'
          icon='material-symbols-more-horiz'
          options={[
            {
              testId: 'duplicate-backup-policy',
              text: 'Duplicate',
            },
            {
              testId: 'delete-backup-policy',
              text: 'Delete',
              menuItemProps: { disabled: !props.deleteAllowed },
            },
          ]}
          onOptionSelected={(option: OptionType): void => {
            switch ((option as OptionMenuItemType).text) {
              case 'Duplicate':
                props.onDuplicate(props.schedule);
                break;
              case 'Delete':
                props.onDelete(props.schedule);
                break;
            }
          }}
        />
      </Schedule>
      <BackupVaultSelectionDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSelect={(vault) => {
          props.onUpdate({ ...props.schedule, vaultId: vault.id });
          setDialogOpen(false);
        }}
        scheduleWindow={(
          frequencyMapping.find((f) => f.windows === props.schedule.windows)
            ?.text || ''
        ).toLowerCase()}
        selectedVaults={[props.schedule.vaultId]}
      />
    </ScheduleContainer>
  );
}
