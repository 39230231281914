import type { InventoryResource } from '@repo/api-gw-sdk';
import { BackupStatus, ResourceType } from '@repo/api-gw-sdk';

import { useRoles } from '@/contexts/useRoles';
import { useRouting } from '@/contexts/useRouting';
import { useDAL } from '@/data/dal';
import {
  InventoryResourceActionsKeys,
  type ResourceAction,
} from '@/data/inventory/actions';

const useInstanceActions = (
  resource: InventoryResource | undefined,
  onEntityChange: () => Promise<void>,
  setAssignBackupPolicyDialogOpen: (isOpen: boolean) => void,
  setExcludeFromBackupDialogOpen: (isOpen: boolean) => void,
  setTakeSnapshotDialogOpen: (isOpen: boolean) => void
) => {
  const dal = useDAL();
  const { isAuthorizedResource } = useRoles();
  const routing = useRouting();

  const triggerTakeSnapshot = () => {
    setTakeSnapshotDialogOpen(true);
  };

  const takeSnapshotAction: ResourceAction = {
    testId: 'take-snapshot-action',
    key: InventoryResourceActionsKeys.TakeSnapshot,
    tooltip: 'Take on-demand snapshot',
    icon: 'material-symbols-backup-outline-rounded',
    isAuthorized: isAuthorizedResource('create:jobs', resource),
    disabled: () =>
      !takeSnapshotAction.isAuthorized ||
      resource?.backupStatus === BackupStatus.Terminated ||
      resource?.backupStatus === BackupStatus.Disconnected,
    execute: () => {
      triggerTakeSnapshot();
    },
    predicate: () =>
      resource?.resourceType === ResourceType.AwsEc2 ||
      resource?.resourceType === ResourceType.AwsRds ||
      resource?.resourceType === ResourceType.AwsS3 ||
      resource?.resourceType === ResourceType.AwsEksNamespace ||
      resource?.resourceType === ResourceType.AtlasMongodbCluster ||
      resource?.resourceType === ResourceType.AzureStorageAccount ||
      resource?.resourceType === ResourceType.AzureVirtualMachine ||
      resource?.resourceType === ResourceType.AzurePostgresql ||
      resource?.resourceType === ResourceType.AzureSqlDatabase,
  };

  const triggerAllowBackups = async (resource: InventoryResource) => {
    await dal.inventory.resources
      .cancelResourceBackupExclusion(resource.id)
      .then(() => onEntityChange());
  };

  const allowBackupsAction: ResourceAction = {
    testId: 'allow-backups-action',
    key: InventoryResourceActionsKeys.AllowBackups,
    isAuthorized: isAuthorizedResource('update:exclude_resource', resource),
    disabled: () => !allowBackupsAction.isAuthorized,
    title: 'Cancel Backup Exclusion',
    tooltip: 'Cancel backup exclusion',
    icon: 'material-symbols-add-circle-outline',
    execute: (resource: InventoryResource) => {
      void triggerAllowBackups(resource);
    },
    predicate: () => resource?.backupStatus === BackupStatus.ExcludedFromBackup,
  };

  const excludeFromBackupAction: ResourceAction = {
    testId: 'exclude-from-backup-action',
    key: InventoryResourceActionsKeys.ExcludeFromBackup,
    isAuthorized: isAuthorizedResource('update:exclude_resource', resource),
    disabled: () => !excludeFromBackupAction.isAuthorized,
    title: 'Exclude from Backup',
    tooltip: 'Exclude from backup',
    icon: 'material-symbols-hide-source-outline-rounded',
    execute: () => {
      setExcludeFromBackupDialogOpen(true);
    },
    predicate: () => resource?.backupStatus !== BackupStatus.ExcludedFromBackup,
  };

  const triggerExploreDB = (resource: InventoryResource) => {
    routing.push(`/db/${resource.id}`);
  };

  const exploreDBAction: ResourceAction = {
    testId: 'explore-db-action',
    key: InventoryResourceActionsKeys.Explore,
    title: 'Explore Database',
    tooltip: 'Explore database',
    icon: 'material-symbols-frame-inspect-rounded',
    isAuthorized: isAuthorizedResource('read:db', resource),
    disabled: () => !exploreDBAction.isAuthorized,
    execute: (resource: InventoryResource) => {
      triggerExploreDB(resource);
    },
    predicate: () =>
      resource?.resourceType === ResourceType.AwsRds ||
      resource?.resourceType === ResourceType.AtlasMongodbCluster ||
      resource?.resourceType === ResourceType.AzureCosmosdbNosql ||
      resource?.resourceType === ResourceType.AzureSqlManagedInstance ||
      resource?.resourceType === ResourceType.AzurePostgresql ||
      resource?.resourceType === ResourceType.AzureSqlDatabase ||
      ((resource?.resourceType === ResourceType.AwsEc2 ||
        resource?.resourceType === ResourceType.AzureVirtualMachine) &&
        !!resource?.classifications?.appsDetails?.apps?.find(
          (app) => app.paths?.length
        )),
  };

  const triggerExploreFiles = (resource: InventoryResource) => {
    routing.push(`/explorer?resourceId=${resource.id}&path=`);
  };

  const exploreFilesAction: ResourceAction = {
    testId: 'explore-files-action',
    key: InventoryResourceActionsKeys.Explore,
    title: 'Explore Files',
    tooltip: 'Explore files',
    icon: 'material-symbols-frame-inspect-rounded',
    isAuthorized: isAuthorizedResource('read:files', resource),
    disabled: () => !exploreFilesAction.isAuthorized,
    execute: (resource: InventoryResource) => {
      triggerExploreFiles(resource);
    },
    predicate: () =>
      resource?.resourceType === ResourceType.AwsEc2 ||
      resource?.resourceType === ResourceType.AwsS3 ||
      resource?.resourceType === ResourceType.AwsEksNamespace ||
      resource?.resourceType === ResourceType.AzureStorageAccount ||
      resource?.resourceType === ResourceType.AzureVirtualMachine,
  };

  const assignToBackupPolicyAction: ResourceAction = {
    testId: 'assign-to-backup-policy-action',
    key: InventoryResourceActionsKeys.AssignToBackupPolicy,
    title: 'Assign to Backup Policy',
    tooltip: 'Assign to backup policy',
    icon: 'material-symbols-bookmark-add-outline',
    isAuthorized: isAuthorizedResource('update:policies', resource),
    disabled: () => !assignToBackupPolicyAction.isAuthorized,
    execute: () => {
      setAssignBackupPolicyDialogOpen(true);
    },
  };

  const actions: ResourceAction[] = [
    assignToBackupPolicyAction,
    exploreFilesAction,
    exploreDBAction,
    takeSnapshotAction,
    excludeFromBackupAction,
    allowBackupsAction,
  ].filter((action) => (action.predicate ? action.predicate() : true));

  return actions;
};

export default useInstanceActions;
