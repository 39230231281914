'use client';

import { Slide, styled } from '@mui/material';
import classnames from 'classnames';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { PanelsMap, type PanelTypes } from '@/components/panels/panelsMap';
import { useWorkspace } from '@/contexts/useWorkspace';
import { PanelSize } from '@/contexts/workspaceContext';

export const LeftSidePanel = () => {
  const { leftPanel } = useWorkspace();
  const { isOpen, Component, setIsOpen } = leftPanel;

  return (
    <StyledPanel
      className='border-s-0 min-w-[388px] w-[388px] sticky'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Component={Component}
      position='left'
    />
  );
};

function getPanelWidth(panelSize: PanelSize | string): string {
  let width = '840px';
  if (panelSize === PanelSize.Small) {
    width = '640px';
  } else if (panelSize === PanelSize.Large) {
    width = '840px';
  } else if (panelSize === PanelSize.Full) {
    width = 'calc(100vw - 59px)';
  } else {
    width = panelSize;
  }
  return width;
}
export const RightSidePanel = () => {
  const { rightPanel } = useWorkspace();
  const { isOpen, Component, setIsOpen, panelSize } = rightPanel;

  const width = getPanelWidth(panelSize);

  return (
    <StyledPanel
      sx={{
        backgroundColor: 'var(--mui-palette-background-paper)',
        boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.2)',
        width: width,
        borderInlineStart:
          (rightPanel.panelSize as PanelSize) === PanelSize.Full
            ? '0'
            : '1px solid var(--border-color)',
        borderInlineEnd:
          (rightPanel.panelSize as PanelSize) === PanelSize.Full
            ? '0'
            : '1px solid var(--border-color)',
      }}
      className='right-0 border-e-0 fixed'
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      Component={Component}
      position='right'
    />
  );
};

const SidePanel = ({
  className,
  isOpen,
  setIsOpen,
  Component,
  position,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  Component: PanelTypes | undefined;
  className: string;
  position: 'right' | 'left';
}) => {
  const path = usePathname();
  const [shouldAnimateExit, setShouldAnimateExit] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setShouldAnimateExit(false);
    }
    setIsOpen(false);
    // don't want to be depend on isOpen, but only when the path changes, to check if the panel was open,
    // than don't animate the exit before setting the panel to be closed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, setIsOpen, setShouldAnimateExit]);

  const Panel = Component?.panel && PanelsMap[Component.panel];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const props = Component?.props || ({} as any);
  const show = !!(isOpen && Panel);

  return (
    <Slide
      direction={position === 'right' ? 'left' : 'right'}
      in={show}
      mountOnEnter
      unmountOnExit
      timeout={{ enter: 500, exit: shouldAnimateExit ? 500 : 0 }}
      easing={{
        enter: 'ease-out',
        exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
      }}
    >
      <div
        data-testid='side-panel'
        className={classnames('h-dvh flex flex-col z-[5] top-0', className)}
      >
        {show && <Panel {...props} />}
      </div>
    </Slide>
  );
};

const StyledPanel = styled(SidePanel)({});
