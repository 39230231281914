import { Stack } from '@mui/material';

import { RegionSelect } from '@/components/regions/regionSelect';
import type { StepProps } from '@/components/wizard/StepProps';

import { SnapshotToolbar } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import { BucketSelectionStep } from '../bucketSelectionStep';
import type { RestoreEKSNamespaceState } from '../restoreEksNamespaceWizard';

export const EksNamespaceConfiguration = (
  props: StepProps<RestoreEKSNamespaceState>
) => {
  const { wizardState: stepState, stepperLabels, back } = props;
  const { S3, restoreMode } = stepState;

  return (
    <StepContainer
      sx={{ padding: '0' }}
      stepperLabels={stepperLabels}
      stepperIndex={stepperLabels.length - 1}
      onBackClick={back}
      canGoNext={() => {
        return (
          !!props.wizardState.region &&
          Boolean(restoreMode === 'select' ? S3.Bucket : S3.BucketOverride)
        );
      }}
      onNextClick={() => props.setNextStep(props.currentStep.next?.[0])}
      toolbarComponent={
        <SnapshotToolbar
          pointInTime={props.wizardState.snapshot.pointInTime}
          vaultId={props.wizardState.snapshot.vaultId}
          accessDeniedItemsCount={
            props.wizardState.snapshot.accessDeniedItemsCount
          }
        />
      }
    >
      <Stack>
        <Stack className='mx-[40px] mt-[24px] w-1/2'>
          <RegionSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={props.wizardState.region}
            onChange={(region) =>
              props.setWizardState((state) => ({
                ...state,
                region,
              }))
            }
          />
        </Stack>
        <BucketSelectionStep {...props} />
      </Stack>
    </StepContainer>
  );
};
