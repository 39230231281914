import {
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { AvailabilityZoneSelect } from '@/components/regions/availabilityZoneSelect';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';
import { PreferencesKey } from '@/data/dal/preferences';

import { SnapshotToolbar } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreEKSNamespaceState } from '../restoreEksNamespaceWizard';

export const EksVolumesConfiguration = (
  props: StepProps<RestoreEKSNamespaceState>
) => {
  const { stepperLabels, back, abort, onSuccess, wizardState, setWizardState } =
    props;
  const dal = useDAL();
  const [error, setError] = useState<string | undefined>();

  const { body: snapshotsBody } = dal.inventory.snapshots.get(
    wizardState.snapshot.id
  );

  const { body: originAZsBody } = dal.restore.availabilityZones.list(
    wizardState.restoreAccount!.id,
    wizardState.resource.region
  );

  const { body: targetAZsBody } = dal.restore.availabilityZones.list(
    wizardState.restoreAccount!.id,
    wizardState.region
  );

  useEffect(() => {
    if (
      !originAZsBody?.availabilityZones.length ||
      !targetAZsBody?.availabilityZones.length ||
      !snapshotsBody?.snapshot.resource?.properties?.awsEksNamespace?.volumes
        ?.length
    ) {
      return;
    }

    setWizardState((state) => ({
      ...state,
      volumes:
        originAZsBody.region === targetAZsBody.region
          ? snapshotsBody.snapshot.resource!.properties!.awsEksNamespace!
              .volumes!
          : snapshotsBody.snapshot.resource!.properties!.awsEksNamespace!.volumes!.map(
              (x) => {
                const originAZs = originAZsBody.availabilityZones.sort();
                const targetAZs = targetAZsBody.availabilityZones.sort();

                const sourceAzIndex = Math.max(
                  originAZs.indexOf(x.availabilityZone),
                  0
                );

                const targetAz = targetAZs[sourceAzIndex % targetAZs.length];
                return { ...x, availabilityZone: targetAz };
              }
            ),
    }));
  }, [snapshotsBody, setWizardState, originAZsBody, targetAZsBody]);

  return (
    <StepContainer
      sx={{ padding: '0' }}
      stepperLabels={stepperLabels}
      stepperIndex={stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={back}
      canGoNext={() => {
        return !!wizardState.volumes.every((vol) => vol.availabilityZone);
      }}
      onNextClick={() => {
        const override = wizardState.restoreMode !== 'select';
        const bucketName =
          (override ? wizardState.S3.BucketOverride : wizardState.S3.Bucket) ??
          '';

        void dal.preferences.updateUserPref(PreferencesKey.RestoreBucket, {
          bucketName,
        });

        const tags = wizardState.resource.tags || {};
        tags['eon:restore'] = 'true';

        void dal.restore.eks
          .restoreNamespace(wizardState.resource.id, wizardState.snapshot.id, {
            tags,
            region: wizardState.region!,
            restoreAccountId: wizardState.restoreAccount!.id,
            filesTarget: {
              s3Bucket: { bucketName, prefix: wizardState.prefix },
            },
            volumeSettings: wizardState.volumes.map((vol) => {
              const tags = vol.tags || {};
              tags['eon:restore'] = 'true';

              return {
                availabilityZone: vol.availabilityZone,
                volumeSettings: vol.volumeSettings,
                providerVolumeId: vol.providerVolumeId,
                tags,
              };
            }),
          })
          .then(() => {
            abort();
            onSuccess('Restore job has started!');
          })
          .catch((e) => {
            setError(`Failed to restore bucket: ${e.message}`);
          });
      }}
      navigationComponent={
        error && (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        )
      }
      toolbarComponent={
        <SnapshotToolbar
          pointInTime={wizardState.snapshot.pointInTime}
          vaultId={wizardState.snapshot.vaultId}
          accessDeniedItemsCount={wizardState.snapshot.accessDeniedItemsCount}
        />
      }
    >
      <Stack>
        {wizardState.volumes.map((vol) => (
          <Accordion
            key={vol.providerVolumeId}
            defaultExpanded={false}
            className='my-[2px]'
          >
            <AccordionSummary>
              <Typography variant='body1'>{vol.providerVolumeId}</Typography>
            </AccordionSummary>
            <AccordionDetails className='p-0'>
              <Stack className='mx-[40px] my-[24px] w-1/2'>
                <AvailabilityZoneSelect
                  accountId={wizardState.restoreAccount?.id}
                  regionName={wizardState.region}
                  availabilityZone={vol.availabilityZone}
                  onChange={(availabilityZone) =>
                    props.setWizardState((state) => ({
                      ...state,
                      volumes: state.volumes.map((v) =>
                        v.providerVolumeId === vol.providerVolumeId
                          ? { ...v, availabilityZone }
                          : v
                      ),
                    }))
                  }
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </StepContainer>
  );
};
