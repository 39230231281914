'use client';

import type {
  DatesSetArg,
  DayCellContentArg,
  EventContentArg,
} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { Box, Stack, Typography } from '@mui/material';
import { SnapshotStatus, type InventorySnapshotVolume } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { dayjs, DATE_FORMAT } from '@/utils/dayjs';

import { PITCalendarWrapper } from './PITCalendarWrapper';
import { SnapshotIndicator } from './SnapshotIndicator';
import { OtherSnapshotsMultipleDots } from './otherSnapshotsMultipleDots';

import { PendingCircle } from '../panels/instance/tabs/pendingCircle';
import { SkullIcon } from '../security/skullIcon';

export interface SnapshotMetadata {
  id: string;
  vaultId: string;
  pointInTime: Date;
  properties?: { volumeProperties?: InventorySnapshotVolume[] };
}

export interface PointInTimeEvent {
  indicatorColor: string | null;
  vaultName?: string;
  start: Date;
  status?: SnapshotStatus;
  accessDeniedItemsCount?: number;
  isProviderSnapshot?: boolean;
  hasSecurityIssue?: boolean;
}

interface InternalPointInTimeEvent extends PointInTimeEvent {
  additionalEventsInDate?: number;
}

interface PointsInTimeCalendarProps {
  isLoading?: boolean;
  events: PointInTimeEvent[];
  selectedDate: Date | undefined;
  onDateSelected: (date: Date | undefined) => void;
  onDateRangeChanged: (start: Date, end: Date) => void;
}

export const PointsInTimeCalendar = (props: PointsInTimeCalendarProps) => {
  const { showPendingSnapshots, securityScan } = useFeatureFlags();
  const { isDemo, isDev, demoSettings } = useEnvironment();
  const securityScanEnabled =
    isDemo || isDev ? demoSettings.ransomware : securityScan;
  const renderEventContent = renderEventContentFactory(showPendingSnapshots);
  const [summarizedEvents, setSummarizedEvents] = useState<
    InternalPointInTimeEvent[]
  >([]);
  const [securityIssuesMapper, setSecurityIssuesMapper] =
    useState<Record<string, boolean>>();

  useEffect(() => {
    const map = new Map<string, InternalPointInTimeEvent[]>();
    const sorted = props.events.sort(
      (a, b) => a.start.getTime() - b.start.getTime()
    );

    sorted.forEach((event) => {
      const date = dayjs.utc(event.start).format(DATE_FORMAT);
      const events = map.get(date) || [];
      if (events.length === 2) {
        events[1].additionalEventsInDate =
          (events[1].additionalEventsInDate || 0) + 1;
      } else {
        events.push(event);
      }
      map.set(date, events);
    });

    // setting end event to be on the same day as the start one
    const events = [...map.values()].flat().map((event) => {
      const start = event.start;
      const end = new Date(start);
      end.setSeconds(end.getSeconds() + 1);
      if (start.getDate() !== end.getDate()) {
        start.setSeconds(start.getSeconds() - 1);
        end.setSeconds(end.getSeconds() - 1);
      }
      return {
        ...event,
        start,
        end,
      };
    });

    setSummarizedEvents(events);

    if (securityScanEnabled) {
      const groupedByDay = props.events.reduce((acc, event) => {
        const date = dayjs.utc(event.start).format(DATE_FORMAT);
        if (event.hasSecurityIssue) {
          acc.set(date, true);
        }
        return acc;
      }, new Map<string, boolean>());

      setSecurityIssuesMapper(Object.fromEntries(groupedByDay));
    }
  }, [props.events, securityScanEnabled]);

  return (
    <PITCalendarWrapper>
      <FullCalendar
        timeZone='UTC'
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        fixedWeekCount={false}
        showNonCurrentDates={false}
        buttonText={{ today: 'Today' }}
        dayCellContent={(arg: DayCellContentArg) =>
          renderDayCell(arg, securityIssuesMapper)
        }
        dayCellClassNames={(args) => {
          const classes: string[] = [];
          if (props.isLoading) {
            classes.push('cell-loading');
          }

          const date = dayjs.utc(args.date);
          if (
            props.selectedDate &&
            dayjs.utc(props.selectedDate).isSame(date, 'date')
          ) {
            classes.push('cell-selected');
          }

          if (securityIssuesMapper?.[date.format(DATE_FORMAT)]) {
            classes.push('cell-error');
          }

          if (
            summarizedEvents.every(
              (x) => !dayjs.utc(x.start).isSame(date, 'date')
            )
          ) {
            classes.push('fc-day-disabled');
          }

          return classes;
        }}
        eventClick={(args) => {
          if (args.event.start) {
            props.onDateSelected(args.event.start);
          }
        }}
        dateClick={(args) => {
          const date = dayjs.utc(args.date);
          if (
            summarizedEvents.some((x) =>
              dayjs.utc(x.start).isSame(date, 'date')
            )
          ) {
            props.onDateSelected(args.date);
          }
        }}
        selectable={true}
        dayHeaders={false}
        events={summarizedEvents}
        eventContent={renderEventContent}
        datesSet={(args: DatesSetArg) => {
          props.onDateRangeChanged(args.start, args.end);
        }}
        height='auto'
      />
    </PITCalendarWrapper>
  );
};

const renderEventContentFactory =
  (showPendingSnapshots: boolean) => (args: EventContentArg) => {
    return (
      <Stack direction='row' alignItems='center' data-testid='snapshot-dot'>
        {args.event._def.extendedProps.isProviderSnapshot ? (
          <OtherSnapshotsMultipleDots />
        ) : (
          <>
            {showPendingSnapshots &&
              args.event._def.extendedProps.status ===
                SnapshotStatus.Pending && (
                <PendingCircle
                  data-testid='snapshot-pending-indicator'
                  backgroundColor={args.event._def.extendedProps.indicatorColor}
                />
              )}

            {args.event._def.extendedProps.status !==
              SnapshotStatus.Pending && (
              <SnapshotIndicator
                data-testid='snapshot-indicator'
                isPartial={
                  !!args.event._def.extendedProps.accessDeniedItemsCount
                }
                backgroundColor={
                  args.event._def.extendedProps.indicatorColor || null
                }
              />
            )}
          </>
        )}
        {args.event._def.extendedProps.additionalEventsInDate &&
          args.event._def.extendedProps.additionalEventsInDate > 0 && (
            <span data-testid='snapshot-more-indicator' className='ml-[4px]'>
              +{args.event._def.extendedProps.additionalEventsInDate}
            </span>
          )}
      </Stack>
    );
  };

const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function renderDayCell(
  arg: DayCellContentArg,
  securityIssuesMapper: Record<string, boolean> | undefined
) {
  return (
    <div>
      {securityIssuesMapper?.[dayjs.utc(arg.date).format(DATE_FORMAT)] && (
        <Box
          marginTop='-36px'
          className='absolute left-1/2 -translate-x-1/2'
          bgcolor='var(--mui-palette-background-paper)'
        >
          <SkullIcon />
        </Box>
      )}
      <Typography>
        {weekday[arg.date.getDay()]} {arg.dayNumberText}
      </Typography>
    </div>
  );
}
