import { Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import { ConvertToEonDetailedColumn } from './convertToEonDetailedColumn';

interface ConvertToEonProviderDetailsProps {
  fields: { label: string; value: ReactNode }[];
}

export const ConvertToEonProviderDetails = (
  props: ConvertToEonProviderDetailsProps
) => {
  return (
    <Stack
      marginBottom='24px'
      width='100%'
      direction='row'
      justifyItems='start'
      gap='40px'
    >
      <ConvertToEonDetailedColumn>
        {props.fields.map((field) => (
          <Typography key={field.label} fontSize={14}>
            {field.label}
          </Typography>
        ))}
      </ConvertToEonDetailedColumn>
      <ConvertToEonDetailedColumn flexGrow={1}>
        {props.fields.map((field) => (
          <Typography key={field.label} fontSize={14}>
            {field.value}
          </Typography>
        ))}
      </ConvertToEonDetailedColumn>
    </Stack>
  );
};
