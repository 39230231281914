interface RetentionTimelineProps {
  color?: string;
  start: Date;
  elapsed: Date;
  end: Date;
}

export default function RetentionTimeline(props: RetentionTimelineProps) {
  const { color, start, elapsed, end } = props;
  const fillColor = color || 'var(--mui-palette-primary-main)';
  const elapsedLength = elapsed.getTime() - start.getTime();
  const totalLength = end.getTime() - start.getTime();
  const elapsedWidth = (elapsedLength / totalLength) * 100;

  return <Timeline fillWidthPercentage={elapsedWidth} fillColor={fillColor} />;
}

export const Timeline = ({
  fillWidthPercentage,
  fillColor,
}: {
  fillWidthPercentage: number;
  fillColor: string;
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='4'
      viewBox='0 0 100% 4'
      fill='none'
    >
      <rect width='100%' height='4' rx='2' fill='var(--mui-palette-divider)' />
      <rect
        width={`${fillWidthPercentage}%`}
        height='4'
        rx='2'
        fill={fillColor}
      />
    </svg>
  );
};
