import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import type { InventoryResource, Snapshot } from '@repo/api-gw-sdk';
import { SnapshotStatus } from '@repo/api-gw-sdk';
import { Fragment, useMemo } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@/components/accordion';
import { SnapshotIndicator } from '@/components/snapshots/SnapshotIndicator';
import { Tag } from '@/components/tag';
import { volumePresets } from '@/components/volumes/volumeSettings';
import { useRoles } from '@/contexts/useRoles';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import useBackupVaults from '@/data/vaults/useBackupVaults';
import { isDateValid } from '@/utils/dateTime';
import { DATE_FORMAT, DATE_TIME_FORMAT, dayjs } from '@/utils/dayjs';
import { fileSizeFormatter } from '@/utils/fileSizeFormatter';

import { Panels } from '../../panels';

export const VolumesSection = ({
  resource,
}: {
  resource: InventoryResource;
}) => {
  const { rightPanel } = useWorkspace();
  const { setComponent } = rightPanel;
  const { isAuthorizedResource } = useRoles();
  const dal = useDAL();
  const { body } = dal.inventory.snapshots.list(resource.id, 0, 100, [
    SnapshotStatus.Completed,
  ]);
  const { data: vaults } = useBackupVaults();

  const volumeToLatestSnapshotMapping = useMemo(
    () =>
      (body?.data || []).reduce<Record<string, Snapshot>>((agg, snapshot) => {
        if (
          snapshot.status === SnapshotStatus.Completed &&
          isDateValid(snapshot.pointInTime)
        ) {
          snapshot.resource?.properties?.awsEc2?.volumes?.forEach((volume) => {
            if (!agg[volume.providerVolumeId]) {
              agg[volume.providerVolumeId] = snapshot;
            } else if (
              snapshot.pointInTime!.getTime() >
              agg[volume.providerVolumeId].pointInTime!.getTime()
            ) {
              agg[volume.providerVolumeId] = snapshot;
            }
          });
        }

        return agg;
      }, {}),
    [body?.data]
  );

  return resource.resourceProperties?.awsEc2?.volumes?.map(
    (volume, index, volumes) => {
      const snapshot = volumeToLatestSnapshotMapping[volume.providerVolumeId];
      const vault = vaults?.find((v) => v.id === snapshot?.vaultId);

      return (
        <Fragment key={volume.providerVolumeId}>
          <Accordion className='px-[40px]'>
            <AccordionSummary
              sx={{
                '.Mui-expanded': {
                  '.snapshot-info': {
                    opacity: '0',
                  },
                },
              }}
            >
              <Stack
                className='w-full'
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
              >
                <Typography className='font-semibold'>{volume.path}</Typography>
                {vault && (
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    className='snapshot-info'
                    sx={{
                      transition: 'linear 0.1s',
                    }}
                  >
                    <SnapshotIndicator
                      backgroundColor={vault.backgroundColor!}
                    />
                    <Typography
                      className='ml-[12px]'
                      sx={{ fontWeight: '400 !important' }}
                    >
                      {dayjs.utc(snapshot.pointInTime).format(DATE_FORMAT)}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </AccordionSummary>
            <AccordionDetails className='py-[12px] pl-[36px] pr-0'>
              {vault && (
                <Stack
                  className='w-full'
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  {isDateValid(snapshot.pointInTime) && (
                    <Stack direction={'row'} alignItems={'center'}>
                      <SnapshotIndicator
                        backgroundColor={vault.backgroundColor!}
                      />
                      <Typography
                        className='ml-[12px]'
                        sx={{ fontWeight: '400 !important' }}
                      >
                        {`Last backed up on ${dayjs.utc(snapshot.pointInTime).format(DATE_TIME_FORMAT)} UTC`}
                      </Typography>
                    </Stack>
                  )}
                  <Button
                    disabled={
                      !isAuthorizedResource('create:restore_resource', resource)
                    }
                    variant='outlined'
                    className='my-[16px]'
                    onClick={() =>
                      setComponent({
                        panel: Panels.RestoreVolumeWizard,
                        props: {
                          resource,
                          providerVolumeId: volume.providerVolumeId,
                          volumeRegion: volume.region,
                          snapshot,
                          actionType: 'volume',
                          originTab: 'overview',
                        },
                      })
                    }
                  >
                    <i
                      className={`material-symbols-settings-backup-restore-rounded text-[18px] mr-[8px] text-primary align-middle`}
                    />
                    Restore
                  </Button>
                </Stack>
              )}
              <Stack direction={'row'} gap={'60px'}>
                <Table
                  className='w-1/2'
                  sx={{
                    td: {
                      padding: '4px 0',
                      borderBottom: 'none',
                    },

                    '& .props-col': {
                      verticalAlign: 'top',
                      fontWeight: '400',
                      width: '140px',
                    },
                  }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell className='props-col'>ID</TableCell>
                      <TableCell>{volume.providerVolumeId}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='props-col'>Type</TableCell>
                      <TableCell>
                        {volumePresets[volume.volumeSettings.type]?.displayName}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className='props-col'>Size</TableCell>
                      <TableCell>
                        {fileSizeFormatter(
                          volume.volumeSettings.sizeBytes,
                          1,
                          false
                        )}
                      </TableCell>
                    </TableRow>
                    {volume.volumeSettings.iops && (
                      <TableRow>
                        <TableCell className='props-col'>IOPS</TableCell>
                        <TableCell>{volume.volumeSettings.iops}</TableCell>
                      </TableRow>
                    )}
                    {volume.volumeSettings.throughput && (
                      <TableRow>
                        <TableCell className='props-col'>Throughput</TableCell>
                        <TableCell>{`${volume.volumeSettings.throughput} MB/s`}</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {!!Object.entries(volume.tags || {}).length && (
                  <Stack className='w-1/2 py-[4px]'>
                    <Typography
                      sx={{
                        color: 'var(--mui-palette-text-primary) !important',
                      }}
                    >
                      Tags
                    </Typography>
                    <Stack
                      className='mt-[12px]'
                      direction={'row'}
                      flexWrap={'wrap'}
                      gap={'8px'}
                    >
                      {Object.entries(volume.tags || {}).map((entry) => (
                        <Tag
                          variant='outlined'
                          key={entry[0]}
                          content={entry.filter((x) => x).join('=')}
                        />
                      ))}
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          {index < volumes.length - 1 && <Divider className='my-[12px]' />}
        </Fragment>
      );
    }
  );
};
