import { Box, Divider, Stack, Typography } from '@mui/material';
import type { CloudAccountConfigurationAwsConfigInner } from '@repo/api-gw-sdk';
import { useEffect, useRef, useState } from 'react';

import OptionMenu from '@/@core/components/option-menu';
import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import { CircleImage } from '@/components/shared/circleImage';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { CloudProviders } from '@/data/inventory/data';

import type { AwsRestoreAccountConfigState } from './awsRestoreAccountConfigFlow';
import { CloudAccountConfigurationAwsConfigDialog } from './cloudAccountConfigurationAwsConfigDialog';
import { DeleteVpcConfigDialog } from './deleteVpcConfigDialog';
import { cloudAccountConfigurationAwsSecurityGroupsKeys } from './types';
import { VpcSelectionDialog } from './vpcSelectionDialog';

import { ReadMoreLink } from '../../readMoreLink';

export const AwsRestoreAccountConfigVpcSelection = (
  props: StepProps<AwsRestoreAccountConfigState>
) => {
  const { snackbar } = useWorkspace();
  const dal = useDAL();
  const addCustomVPCSettingsButtonRef = useRef<HTMLButtonElement>(null);

  const [configForDeletion, setConfigForDeletion] = useState<
    CloudAccountConfigurationAwsConfigInner | undefined
  >(undefined);
  const [showVpcSelectionDialog, setShowVpcSelectionDialog] = useState(false);
  const [selectedConfig, setSelectedConfig] = useState<
    CloudAccountConfigurationAwsConfigInner | undefined
  >();
  const [editedConfig, setEditedConfig] = useState<
    CloudAccountConfigurationAwsConfigInner | undefined
  >();

  const { body: availabilityZonesBody } = dal.restore.availabilityZones.list(
    props.wizardState.account.id,
    selectedConfig?.region
  );

  const regionConfigurations = Object.entries(
    props.wizardState.config.awsConfig.reduce<
      Record<string, CloudAccountConfigurationAwsConfigInner[]>
    >((agg, val) => {
      agg[val.region] = agg[val.region] || [];
      agg[val.region].push(val);
      return agg;
    }, {})
  ).sort((a, b) => a[0].localeCompare(b[0]));

  useEffect(() => {
    if (
      !selectedConfig ||
      !props.wizardState.config.awsConfig.includes(selectedConfig)
    ) {
      setSelectedConfig(regionConfigurations[0]?.[1][0]);
    }
  }, [
    props.wizardState.config.awsConfig,
    regionConfigurations,
    selectedConfig,
  ]);

  return (
    <StepContainer
      hideStepper
      onBackClick={props.back}
      canGoNext={() => true}
      nextButtonText={'Save Changes'}
      backButtonText='Cancel'
      onNextClick={props.finish}
      sx={{ height: '100%' }}
    >
      <Stack sx={{ height: '100%' }}>
        <Stack gap='12px' className='px-[50px]'>
          <Stack direction='row' alignItems='center'>
            {CloudProviders[props.wizardState.account.cloudProvider] && (
              <CircleImage
                key={props.wizardState.account.cloudProvider}
                alt={
                  CloudProviders[props.wizardState.account.cloudProvider]
                    ?.displayName
                }
                src={
                  CloudProviders[props.wizardState.account.cloudProvider]?.logo
                }
                className='mr-[8px]'
              />
            )}
            {props.wizardState.account.providerAccountId}
          </Stack>
          <Typography
            className='max-w-[680px]'
            lineHeight={'20px'}
            fontWeight={300}
          >
            {`When you restore a resource, a temporary server runs the restore operations. Eon chooses an availability zone and security group depending on the destination and type of resource you're restoring.`}
          </Typography>
          <Typography
            className='max-w-[680px]'
            lineHeight={'20px'}
            fontWeight={300}
          >
            {`If your default VPC resources have been deleted or have outbound limitations, you'll need to customize VPCs and specify the connectivity options here. `}
            <ReadMoreLink href='/docs/user-guide/configuration/connect-your-cloud-accounts/restore-accounts/restore-server-connectivity-requirements' />
          </Typography>
        </Stack>
        <Divider className='my-[40px] mx-[-40px]' />
        <Stack className='px-[50px]' direction='row' flexGrow={1}>
          <Stack className='w-[280px]' gap='40px'>
            {!!props.wizardState.config.awsConfig.length && (
              <Stack gap='40px'>
                <Typography>Customized VPCs</Typography>
                <Stack gap='24px'>
                  {regionConfigurations.map(([regionName, configs], index) => (
                    <Stack key={index} gap='24px'>
                      <Typography
                        className='uppercase'
                        variant='body2'
                        sx={{
                          fontSize: '12px',
                        }}
                      >
                        {regionName}
                      </Typography>
                      {configs.map((config) => (
                        <Stack
                          key={config.vpc}
                          justifyContent='space-between'
                          direction='row'
                          alignItems='center'
                          className='pr-[22px] cursor-pointer'
                          sx={{
                            ...(selectedConfig === config
                              ? { borderRight: '2px solid black' }
                              : { borderRight: '2px solid transparent' }),
                          }}
                          onClick={() => setSelectedConfig(config)}
                        >
                          <Typography>{config.vpc}</Typography>
                          <OptionMenu
                            iconClassName='text-textPrimary'
                            icon='material-symbols-more-horiz'
                            options={[
                              {
                                testId: 'delete-config',
                                text: 'Delete',
                                menuItemProps: {
                                  onClick: () => setConfigForDeletion(config),
                                },
                              },
                            ]}
                          />
                        </Stack>
                      ))}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
            <Box>
              <ButtonWithIcon
                ref={addCustomVPCSettingsButtonRef}
                variant='outlined'
                icon='material-symbols-add-rounded'
                text={
                  props.wizardState.config.awsConfig.length
                    ? 'Customize Another VPC'
                    : 'Customize VPC'
                }
                onClick={() => setShowVpcSelectionDialog(true)}
              />
            </Box>
            {!!props.wizardState.config.awsConfig.length && (
              <>
                <Divider className='mt-[20px] ml-[-100px]' />
                <Typography
                  className='w-[208px]'
                  fontWeight={'300'}
                  lineHeight={'20px'}
                >
                  For all other VPCs, Eon automatically chooses a suitable
                  subnet and security group.
                </Typography>
              </>
            )}
            <DeleteVpcConfigDialog
              isOpen={!!configForDeletion}
              onCancel={() => setConfigForDeletion(undefined)}
              onConfirm={() => {
                snackbar.showMessage('VPC settings deleted successfully');
                setConfigForDeletion(undefined);
                props.setWizardState((state) => ({
                  ...state,
                  config: {
                    ...state.config,
                    awsConfig: state.config.awsConfig.filter(
                      (x) => x !== configForDeletion
                    ),
                  },
                }));
              }}
            />
            {showVpcSelectionDialog && (
              <VpcSelectionDialog
                config={props.wizardState.config.awsConfig}
                buttonRef={addCustomVPCSettingsButtonRef}
                accountId={props.wizardState.account.id}
                onClose={(regionName, vpc) => {
                  setShowVpcSelectionDialog(false);
                  if (
                    !regionName ||
                    !vpc ||
                    props.wizardState.config.awsConfig.some(
                      (x) => x.region === regionName && x.vpc === vpc
                    )
                  ) {
                    return;
                  }

                  const config = {
                    region: regionName,
                    vpc,
                    cloudAccountConfigurationAwsSubnets: [],
                    cloudAccountConfigurationAwsSecurityGroups: [],
                  };

                  props.setWizardState((state) => ({
                    ...state,
                    config: {
                      ...state.config,
                      awsConfig: [...state.config.awsConfig, config],
                    },
                  }));
                  setSelectedConfig(config);
                  setEditedConfig(config);
                }}
              />
            )}
          </Stack>
          {!!props.wizardState.config.awsConfig.length && (
            <Divider flexItem orientation='vertical' />
          )}
          {selectedConfig && (
            <Stack className='ml-[40px]' gap='40px' flexGrow={1}>
              <Stack direction='row' justifyContent='space-between'>
                <Stack direction='row' gap='4px'>
                  <Typography component='span'>{`Restore server in`}</Typography>
                  <Typography component='span' className='font-semibold'>
                    {selectedConfig.vpc}
                  </Typography>
                  <Typography component='span'>{`will use these settings:`}</Typography>
                </Stack>
                <ButtonWithIcon
                  variant='outlined'
                  icon='material-symbols-stylus-outline'
                  text='Edit VPC'
                  onClick={() => setEditedConfig(selectedConfig)}
                />
              </Stack>
              <Stack direction='row' gap='100px'>
                <Stack gap='36px' className='w-[240px]'>
                  <Typography className='font-semibold'>
                    Subnets per availability zone
                  </Typography>
                  <Typography lineHeight={'20px'} fontWeight={300}>
                    {`The restore server will be launched in the subnets you specify here. Make sure outbound access is open in the selected subnets.`}
                  </Typography>
                </Stack>
                <Stack
                  flexGrow={1}
                  gap='24px'
                  divider={<Divider sx={{ borderStyle: 'dashed' }} />}
                >
                  {(availabilityZonesBody?.availabilityZones || [])
                    .sort()
                    .map((x) => {
                      const subnetId =
                        selectedConfig.cloudAccountConfigurationAwsSubnets.find(
                          (y) => y.availabilityZone === x
                        )?.subnetId;

                      return (
                        <Stack key={x}>
                          <Stack direction='row' alignItems='center' gap='40px'>
                            <Typography className='w-[150px]'>{x}</Typography>
                            {subnetId && <Typography>{subnetId}</Typography>}
                            {!subnetId && (
                              <Typography
                                color={'var(--mui-palette-text-disabled)'}
                              >
                                Default subnet
                              </Typography>
                            )}
                          </Stack>
                        </Stack>
                      );
                    })}
                </Stack>
              </Stack>
              <Divider className='my-[20px]' />
              <Stack direction='row' gap='100px'>
                <Stack gap='36px' className='w-[240px]'>
                  <Typography className='font-semibold'>
                    Security groups
                  </Typography>
                  <Typography lineHeight={'20px'} fontWeight={300}>
                    {`The restore server and restored RDS instance will use the specified security groups. Make sure outbound access is open, and that the RDS and restore server security groups are open to each other.`}
                  </Typography>
                </Stack>
                <Stack
                  flexGrow={1}
                  gap='24px'
                  divider={<Divider sx={{ borderStyle: 'dashed' }} />}
                >
                  {Object.entries(
                    cloudAccountConfigurationAwsSecurityGroupsKeys
                  ).map(([key, title]) => {
                    const securityGroupId =
                      selectedConfig.cloudAccountConfigurationAwsSecurityGroups.find(
                        (y) => y.key === key
                      )?.securityGroupId;

                    return (
                      <Stack key={key}>
                        <Stack direction='row' alignItems='center' gap='40px'>
                          <Typography className='w-[150px]'>{title}</Typography>
                          {securityGroupId && (
                            <Typography>{securityGroupId}</Typography>
                          )}
                          {!securityGroupId && (
                            <Typography
                              color={'var(--mui-palette-text-disabled)'}
                            >
                              Default security group
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      <CloudAccountConfigurationAwsConfigDialog
        accountId={props.wizardState.account.id}
        config={editedConfig}
        availabilityZones={availabilityZonesBody?.availabilityZones || []}
        onCancel={() => setEditedConfig(undefined)}
        onSave={(updatedConfig) => {
          props.setWizardState((state) => ({
            ...state,
            config: {
              ...state.config,
              awsConfig: state.config.awsConfig.map((x) =>
                x === editedConfig ? updatedConfig : x
              ),
            },
          }));
          setSelectedConfig(updatedConfig);
          setEditedConfig(undefined);
        }}
      />
    </StepContainer>
  );
};
