import { Stack, Typography } from '@mui/material';
import type { ReactNode } from 'react';

import dayjs, { DATE_FORMAT } from '@/utils/dayjs';

import RetentionTimeline from './retentionTimeline';

interface SnapTimelineProps {
  label?: string;
  pointDate: Date;
  hidePointDateLabel?: boolean;
  expirationDate?: Date;
  DotIcon: ReactNode;
  color?: string;
  showTimeline?: boolean;
}

export const SnapTimeline = (props: SnapTimelineProps) => {
  const {
    DotIcon,
    label,
    pointDate,
    expirationDate,
    showTimeline,
    color,
    hidePointDateLabel,
  } = props;

  const today = dayjs.utc().startOf('day');
  const expiredDaysAgo = today.diff(
    dayjs.utc(expirationDate).startOf('day'),
    'day'
  );

  const isExpired = expiredDaysAgo > 0;
  const isExpiringToday = expiredDaysAgo === 0;

  return (
    <Stack direction='row' alignItems='start' gap='12px'>
      {label && <Typography width='60px'>{label}</Typography>}
      {DotIcon}
      <Stack gap='8px' flexGrow={1}>
        {!hidePointDateLabel && (
          <Typography>{dayjs.utc(pointDate).format(DATE_FORMAT)}</Typography>
        )}
        {showTimeline && expirationDate && (
          <>
            <RetentionTimeline
              color={
                isExpired || isExpiringToday
                  ? 'var(--mui-palette-error-main)'
                  : color
              }
              start={pointDate}
              end={expirationDate}
              elapsed={new Date()}
            />
            <Typography
              color={
                isExpired || isExpiringToday
                  ? 'var(--mui-palette-error-main)'
                  : 'var(--mui-palette-secondary-main)'
              }
              alignSelf='flex-end'
              className='font-semibold'
              variant='subtitle1'
            >
              {isExpired
                ? `Expired ${expiredDaysAgo} days ago`
                : isExpiringToday
                  ? `Expires today`
                  : `Expires in ${dayjs.utc(expirationDate).format(DATE_FORMAT)}`}
            </Typography>
          </>
        )}
      </Stack>
    </Stack>
  );
};
