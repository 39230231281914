import {
  Box,
  Button,
  Divider,
  Link,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  AzureManagementGroup,
  AzureSubscription,
  type AzureOnboardingStatus,
  type AzureTenant,
} from '@repo/api-gw-sdk';
import React, { useRef, useState } from 'react';

import { useUser } from '@/contexts/useUser';
import { useDAL } from '@/data/dal';

import type { AccountType } from './useCreateAccount';

enum AzureDeploymentMethod {
  Interactive = 'Interactive',
  Bash = 'Bash',
}

interface CreateAzureAccountPanelProps {
  accountType: AccountType;
  onCreated: (success: boolean, sourceAccountId: string) => void;
  setLoading: (loading: boolean) => void;
  loading: boolean;
}

export default function CreateAzureAccountPanel(
  props: CreateAzureAccountPanelProps
) {
  const user = useUser();
  const dal = useDAL();
  const requestId = useRef<string>(crypto.randomUUID());

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedTenantId, setSelectedTenantId] = useState<
    string | undefined
  >();
  const [tenants, setTenants] = useState<AzureTenant[] | undefined>();
  const [managementGroups, setManagementGroups] = useState<
    AzureManagementGroup[] | null
  >();
  const [subscriptions, setSubscriptions] = useState<
    AzureSubscription[] | null
  >();
  const [selectedEntity, setSelectedEntity] = useState<
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    AzureManagementGroup | AzureSubscription | null
  >(null);
  const [onboardingStatus, setOnboardingStatus] = useState<
    AzureOnboardingStatus[] | null
  >(null);
  const [deploymentMethod, setDeploymentMethod] =
    useState<AzureDeploymentMethod>(AzureDeploymentMethod.Interactive);
  const [state, setState] = useState<'initial' | 'pending' | 'completed'>(
    'initial'
  );

  const { body: connectIdRes } = dal.azure.getAccountConnectId();

  /**
   * Switch directory, then *pass the new tenant ID* into openConsentDialog
   * so that openConsentDialog is guaranteed to use the updated ID.
   */
  const switchDirectory = (tenantId: string) => {
    setSelectedTenantId(tenantId);
    setManagementGroups(null);
    setSubscriptions(null);
    setTenants(undefined);
    setSelectedEntity(null);
    setOnboardingStatus(null);
    setErrorMessage(null);

    // Pass the new tenant ID directly
    openConsentDialog(tenantId);
  };

  /**
   * Updated to accept an optional tenantIdParam.
   * If provided, use that; otherwise fall back to the selectedTenantId state.
   */
  const openConsentDialog = (tenantIdParam?: string) => {
    const tid = tenantIdParam ?? selectedTenantId;
    const width = 600,
      height = 800;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    props.setLoading(true);
    setErrorMessage(null);
    setManagementGroups(null);
    setSubscriptions(null);
    setSelectedEntity(null);
    setOnboardingStatus(null);

    void dal.azure.getConsentUrl(requestId.current, tid).then((resp) => {
      if (resp.error) {
        props.setLoading(false);
        setErrorMessage(resp.error);
        return;
      }

      const authWindow = window.open(
        resp.url,
        'authWindow',
        `width=${width},height=${height},top=${top},left=${left}`
      );

      // Monitor the popup window
      const interval = setInterval(() => {
        try {
          if (!authWindow) {
            clearInterval(interval);
            props.setLoading(false);
            return;
          }

          // Close if user closes the window
          if (authWindow.closed) {
            clearInterval(interval);
            void dal.azure
              .listSubscriptions(requestId.current)
              .then((resp) => {
                if (resp.managementGroups?.length > 0) {
                  const firstSubTenantId = resp.managementGroups
                    .filter(
                      (mg) => mg.subscriptions && mg.subscriptions.length > 0
                    )
                    .map((mg) => mg.subscriptions?.[0]?.tenantId)
                    .find((tenantId) => tenantId !== undefined);

                  setSelectedTenantId(firstSubTenantId);
                } else if (
                  resp.subscriptions &&
                  resp.subscriptions?.length > 0
                ) {
                  setSelectedTenantId(resp.subscriptions[0].tenantId);
                }

                setManagementGroups(resp.managementGroups);
                setSubscriptions(resp.subscriptions);
                setTenants(resp.tenants);
              })
              .catch(() => {
                props.setLoading(false);
                setErrorMessage('Operation failed');
                setSubscriptions(null);
                setManagementGroups(null);
              });
          }
        } catch {
          // Ignore cross-origin errors
        }
      }, 500);
    });
  };

  const onboardSubscriptions = () => {
    setState('pending');
    if (!selectedEntity) return;
    if (!selectedTenantId) return;

    void dal.azure
      .onboardSubscriptions(
        requestId.current,
        props.accountType,
        selectedTenantId,
        selectedEntity
      )
      .then(() => {
        let onboardingInProgress = false;

        const checkOnboardingStatus = async () => {
          if (onboardingInProgress) return; // Prevent overlapping calls
          onboardingInProgress = true;

          try {
            const resp = await dal.azure.getOnboardingStatus(requestId.current);
            setOnboardingStatus(resp.entities);

            const allCompleted = resp.entities.every(
              (entity) =>
                entity.status !== 'Accepted' && entity.status !== 'Running'
            );

            if (allCompleted) {
              setState('completed');
            } else {
              setTimeout(() => {
                void checkOnboardingStatus();
              }, 4000); // Retry after 4 seconds
            }
          } catch {
            setState('completed');
            setManagementGroups(null);
            setSubscriptions(null);
            setErrorMessage(`Failed to onboard ${getSelectedEntityName()}`);
          } finally {
            onboardingInProgress = false; // Reset the flag after each call
          }
        };

        void checkOnboardingStatus();
      })
      .catch(() => {
        setState('completed');
        setErrorMessage(`Failed to onboard ${getSelectedEntityName()}`);
      });
  };

  const getSelectedEntityName = () => {
    if (selectedEntity instanceof AzureSubscription) {
      return 'Subscription';
    }
    if (selectedEntity instanceof AzureManagementGroup) {
      return 'Management Group';
    }
    return '';
  };

  return (
    <Box className='px-[20px]'>
      {/* 1) If no management groups yet, show the initial screen */}
      {state === 'initial' && !managementGroups && !subscriptions && (
        <>
          {!props.loading && (
            <>
              <Box className='my-[15px]'>
                Before we proceed,{' '}
                <b>
                  please ensure you have sufficient permissions to create and
                  manage a service principal and assign roles in your Azure
                  subscriptions
                  <Tooltip
                    title={
                      <Box>
                        To connect your Azure subscriptions, Eon requires
                        creating a service principal and assigning specific
                        roles.
                        <br />
                        <br />
                        In a Microsoft Entra ID directory where user setting{' '}
                        <b>Users can register applications</b> has been set to{' '}
                        <b>No</b>, you must be a member of one of the following
                        Microsoft Entra ID built-in roles (which have the
                        action:
                        <code>
                          microsoft.directory/applications/createAsOwner
                        </code>{' '}
                        or
                        <code>microsoft.directory/applications/create</code>):
                        <br />
                        <br />
                        <ul className='list-disc ml-[24px]'>
                          <li>Global Administrator</li>
                          <li>Application Administrator</li>
                          <li>Cloud Application Administrator</li>
                          <li>Application Developer</li>
                        </ul>
                      </Box>
                    }
                    slotProps={{
                      tooltip: {
                        sx: {
                          maxWidth: '600px',
                          padding: '16px',
                        },
                      },
                    }}
                  >
                    <i className='info-icon material-symbols-info-outline h-[20px] w-[20px] p-[2px] mb-[2px] ml-[4px] align-middle cursor-default' />
                  </Tooltip>
                </b>
              </Box>
              <Box className='my-[15px] mb-[30px]'>
                <Stack direction='column' gap='16px'>
                  <Typography marginBottom='8px' variant='body2'>
                    Deployment method
                  </Typography>
                  <Stack
                    className='cursor-pointer'
                    direction='row'
                    alignItems='center'
                    gap='12px'
                    onClick={() =>
                      setDeploymentMethod(AzureDeploymentMethod.Interactive)
                    }
                  >
                    <Radio
                      data-testid='full-instance-radio'
                      size='small'
                      checked={
                        deploymentMethod === AzureDeploymentMethod.Interactive
                      }
                    />
                    <Typography variant='body2'>Interactive</Typography>
                  </Stack>
                  <Stack
                    className='cursor-pointer'
                    direction='row'
                    alignItems='center'
                    gap='12px'
                    onClick={() =>
                      setDeploymentMethod(AzureDeploymentMethod.Bash)
                    }
                  >
                    <Radio
                      data-testid='specific-vol-radio'
                      size='small'
                      checked={deploymentMethod === AzureDeploymentMethod.Bash}
                    />
                    <Typography variant='body2'>Bash</Typography>
                  </Stack>
                </Stack>
              </Box>
              <Divider className='mb-[24px]' />
            </>
          )}
          {deploymentMethod === AzureDeploymentMethod.Interactive && (
            <>
              <Box className='my-[15px]'>
                After you log in, we&rsquo;ll query the Microsoft Graph to
                retrieve the Eon application principal ID, then deploy an ARM
                template to update the required RBAC roles on the selected
                permissions.
              </Box>
              <Box className='flex flex-col items-center justify-center my-[30px] gap-[8px]'>
                <Button
                  variant='contained'
                  disabled={props.loading}
                  data-testid='azure-login-btn'
                  onClick={() => openConsentDialog()}
                  sx={{ width: '400px' }}
                >
                  {props.loading && (
                    <i className='line-md-loading-loop w-[20px] h-[20px] mr-[8px]' />
                  )}
                  Open Microsoft Azure{' '}
                  <i className='material-symbols-open-in-new w-[20px] h-[20px] ml-[8px]' />
                </Button>
              </Box>
            </>
          )}
          {deploymentMethod === AzureDeploymentMethod.Bash && (
            <>
              <Box className='my-[15px]'>
                Run this command to connect your Azure management group or
                subscription:
              </Box>
              <Box
                className='flex flex-col items-center justify-center my-[30px]'
                gap='8px'
              >
                <code className='p-[8px] bg-gray-100 rounded-md select-all break-pretty'>
                  curl -fsSL
                  https://eon.blob.core.windows.net/public/eon-azure.sh -o
                  eon-azure.sh && chmod +x eon-azure.sh && ./eon-azure.sh
                  --connect-id {connectIdRes?.connectionId} --project-id{' '}
                  {user.currentProjectId} --location {connectIdRes?.location}{' '}
                  --account-type {props.accountType.toLowerCase()}
                </code>
                <Button
                  variant='contained'
                  disabled={props.loading}
                  data-testid='azure-connect-complete-btn'
                  onClick={() => props.onCreated(true, '')}
                  sx={{ width: '400px', mt: '24px' }}
                >
                  Close
                </Button>
              </Box>
            </>
          )}
        </>
      )}

      {/* 2) Once management groups are fetched, show the selection screen */}
      {state !== 'completed' && (
        <>
          <Box className='my-[15px]'>
            {tenants && tenants?.length > 1 && (
              <>
                <Box className='my-[15px]'>Choose a tenant:</Box>
                <Box className='my-[15px]'>
                  <ul className='ml-[24px] list-disc'>
                    {tenants?.map((tenant) => (
                      <li key={tenant.id}>
                        <Stack
                          direction='row'
                          alignItems='center'
                          sx={{
                            '& a.hover-vis': {
                              visibility: 'hidden',
                            },
                            '&:hover a.hover-vis': {
                              visibility: 'visible',
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight:
                                selectedTenantId === tenant.id
                                  ? 'bold'
                                  : 'normal',
                            }}
                          >
                            {tenant.displayName}
                          </Typography>
                          {state === 'initial' &&
                            selectedTenantId !== tenant.id && (
                              <Link
                                className='hover-vis ml-[8px]'
                                onClick={() => switchDirectory(tenant.id)}
                                href='#'
                                underline='hover'
                              >
                                Switch
                              </Link>
                            )}
                        </Stack>
                      </li>
                    ))}
                  </ul>
                </Box>
              </>
            )}
            {(managementGroups || subscriptions) && (
              <>
                <Box className='my-[15px]'>
                  Choose a management group or subscription to connect:
                </Box>
                <Box className='my-[15px]'>
                  {managementGroups
                    ?.filter(
                      (mg) => mg.subscriptions && mg.subscriptions.length > 0
                    )
                    .map((mg) => (
                      <React.Fragment key={mg.id}>
                        <Stack
                          direction='row'
                          alignItems='center'
                          gap='4px'
                          sx={
                            props.accountType !== 'source'
                              ? {
                                  mt: '20px',
                                  mb: '8px',
                                }
                              : {}
                          }
                        >
                          {props.accountType === 'source' && (
                            <Radio
                              disabled={state !== 'initial'}
                              checked={selectedEntity?.id === mg.id || false}
                              onChange={() => setSelectedEntity(mg)}
                            />
                          )}
                          <Typography>{mg.name}</Typography>
                        </Stack>
                        {mg.subscriptions?.map((sub) => (
                          <Stack
                            className='ml-[24px]'
                            direction='row'
                            key={sub.id}
                            alignItems='center'
                            gap='4px'
                          >
                            <Radio
                              disabled={state !== 'initial'}
                              checked={
                                selectedEntity?.id === sub.id ||
                                (selectedEntity &&
                                  sub.id.includes(selectedEntity.id)) ||
                                false
                              }
                              onClick={() => setSelectedEntity(sub)}
                            />
                            <Typography>{sub.name}</Typography>
                          </Stack>
                        ))}
                      </React.Fragment>
                    ))}
                  {subscriptions?.map((sub) => (
                    <Stack
                      key={sub.id}
                      className='ml-[24px]'
                      direction='row'
                      alignItems='center'
                      gap='4px'
                    >
                      <Radio
                        disabled={state !== 'initial'}
                        checked={
                          selectedEntity?.id === sub.id ||
                          (selectedEntity &&
                            sub.id.includes(selectedEntity.id)) ||
                          false
                        }
                        onClick={() => setSelectedEntity(sub)}
                      />
                      <Typography>{sub.name}</Typography>
                    </Stack>
                  ))}
                </Box>
                <Box
                  className='flex flex-col justify-center items-center my-[30px]'
                  gap='8px'
                >
                  <Button
                    variant='contained'
                    // Disabled if we haven't selected any entity or if we're not in 'initial'
                    disabled={state !== 'initial' || !selectedEntity}
                    onClick={() => onboardSubscriptions()}
                    sx={{ width: '400px' }}
                  >
                    {state === 'pending' && (
                      <>
                        <i className='line-md-loading-loop w-[20px] h-[20px] mr-[8px]' />
                        Connecting your {getSelectedEntityName()}...
                      </>
                    )}
                    {state !== 'pending' && <>Connect</>}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </>
      )}

      {/* 3) Once completed, show the onboarding status screen */}
      {state === 'completed' && (
        <Box className='my-[15px]'>
          <Box className='my-[15px]'>
            <b>Operation completed.</b>
            <Typography>
              Please check the status of the operation below:
            </Typography>
          </Box>
          <ul
            className='my-[15px]'
            style={{ listStyle: 'disc', marginLeft: '20px' }}
          >
            {onboardingStatus?.map((os) => (
              <li key={os.entityId}>
                {os.entityName} - {os.status}
              </li>
            ))}
          </ul>
          <Box
            className='flex flex-col justify-center items-center my-[30px]'
            gap='8px'
          >
            <Button
              variant='contained'
              onClick={() =>
                props.onCreated(
                  onboardingStatus?.every((s) => s.status !== 'Failed') ||
                    false,
                  onboardingStatus?.find((s) => s.status === 'Succeeded')
                    ?.sourceAccountId || ''
                )
              }
              sx={{ width: '400px' }}
            >
              Close
            </Button>
          </Box>
        </Box>
      )}

      {/* 4) Error message display */}
      {errorMessage && (
        <Typography variant='body1' color='error' data-testid='error-message'>
          <Stack className='my-[15px]' direction='row' alignItems='center'>
            <i className='material-symbols-error-outline-rounded mr-[8px]'></i>
            {errorMessage}
          </Stack>
        </Typography>
      )}
    </Box>
  );
}
