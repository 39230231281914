import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';

import type { StepProps } from '@/components/wizard/StepProps';
import { useEnvironment } from '@/contexts/useEnvironment';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';
import { useDAL } from '@/data/dal';

import { getSupportedNotificationTypes, NotificationTypes } from './data';
import type { UpsertNotificationPolicyState } from './upsertNotificationPolicyFlow';

import { StepContainer } from '../wizard/StepContainer';
import { RenameEntityName } from '../wizard/renameEntityName';

export const NotificationPolicyNotifications = (
  props: StepProps<UpsertNotificationPolicyState>
) => {
  const dal = useDAL();
  const { securityScan } = useFeatureFlags();
  const { isDemo, isDev, demoSettings } = useEnvironment();
  const securityScanEnabled =
    isDemo || isDev ? demoSettings.ransomware : securityScan;

  return (
    <StepContainer
      hideStepper
      onBackClick={props.back}
      canGoNext={() => props.wizardState.notificationTypes?.length > 0}
      nextButtonText={
        props.wizardState.id ? 'Test and Save' : 'Test and Create'
      }
      onNextClick={() => {
        if (props.wizardState.id) {
          void dal.notifications.policies
            .update(props.wizardState)
            .then(props.finish);
        } else {
          void dal.notifications.policies
            .create(props.wizardState)
            .then(props.finish);
        }
      }}
      navigationComponent={
        <RenameEntityName
          label='Notification policy name'
          name={props.wizardState.name}
          onChange={(name) =>
            props.setWizardState((state) => ({ ...state, name }))
          }
        />
      }
    >
      <Stack className='flex-grow'>
        <Stack
          className='px-[60px] flex-grow'
          gap='24px'
          color={'var(--mui-palette-text-primary)'}
        >
          <Typography>{`Choose notifications to receive on this policy:`}</Typography>
          <Stack>
            {getSupportedNotificationTypes(securityScanEnabled).map((x) => (
              <FormControlLabel
                key={x}
                className='w-full'
                control={
                  <Checkbox
                    sx={{ padding: '10px 10px 10px 30px' }}
                    size='small'
                    checked={props.wizardState.notificationTypes?.includes(x)}
                    onChange={(event, value) =>
                      props.setWizardState((state) => ({
                        ...state,
                        notificationTypes: [
                          ...(state.notificationTypes || []).filter(
                            (y) => y !== x
                          ),
                          ...(value ? [x] : []),
                        ],
                      }))
                    }
                  />
                }
                label={NotificationTypes[x]?.title}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
    </StepContainer>
  );
};
