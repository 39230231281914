import {
  Box,
  Button,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { S3ExclusionRule } from '@repo/api-gw-sdk';
import { useState } from 'react';

import { PanelWrapper } from '@/components/panels/panelWrapper';
import { useWorkspace } from '@/contexts/useWorkspace';

export interface ExclusionRuleEditState {
  id: string;
}

export interface ExclusionRulePanelProps {
  entity: S3ExclusionRule;
  onSave: (rule: S3ExclusionRule) => void;
}

export default function EditBackupVaultPanel(props: ExclusionRulePanelProps) {
  const [data, setData] = useState(props.entity);
  const { rightPanel } = useWorkspace();
  const { setIsOpen } = rightPanel;
  const close = () => setIsOpen(false);

  return (
    <PanelWrapper
      header={{
        onClose: close,
        title: props.entity.id ? 'Edit Rule' : 'Create Rule',
      }}
    >
      <Box className='h-full flex flex-col p-[20px]'>
        <Typography variant='body1'>
          Set up a rule to exclude specific S3 objects from being backed up.
        </Typography>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Bucket Name (Set * for all buckets)</FormLabel>
          <TextField
            size='small'
            defaultValue={data.bucket}
            inputProps={{ 'data-testid': 'bucket-name' }}
            onChange={(event) => {
              setData({
                ...data,
                bucket: event.target.value,
              });
            }}
          />
        </FormControl>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Object Prefix (optional)</FormLabel>
          <TextField
            size='small'
            defaultValue={data.bucketPrefix}
            inputProps={{ 'data-testid': 'bucket-name' }}
            onChange={(event) => {
              setData({
                ...data,
                bucketPrefix: event.target.value,
              });
            }}
          />
        </FormControl>
        <FormControl size='small' className='w-full mt-[24px]'>
          <FormLabel>Object Modification Date (optional)</FormLabel>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={data.modificationDate || null}
              onChange={(newValue) => {
                setData({
                  ...data,
                  modificationDate: newValue || undefined,
                });
              }}
            />
          </LocalizationProvider>
        </FormControl>
      </Box>
      <Box className='flex justify-end px-[20px] py-[16px]'>
        <Button
          size='small'
          variant='outlined'
          className='mr-[12px]'
          onClick={() => setIsOpen(false)}
        >
          {'Cancel'}
        </Button>
        <Button
          size='small'
          variant='contained'
          data-testid='save-vault'
          onClick={() => props.onSave(data)}
        >
          {props.entity.id ? 'Save Changes' : 'Create'}
        </Button>
      </Box>
    </PanelWrapper>
  );
}
