import { Stack, Tabs, Tab } from '@mui/material';
import type { Provider } from '@repo/api-gw-sdk';

const snapshotsOrigins = ['eon', 'other'] as const;
export type SnapshotsOrigin = (typeof snapshotsOrigins)[number];

interface SnapshotsOriginTabsProps {
  onOriginSelected: (origin: SnapshotsOrigin) => void;
  selectedOrigin: SnapshotsOrigin;
  provider: Provider;
}

export default function SnapshotsOriginTabs(props: SnapshotsOriginTabsProps) {
  const { onOriginSelected, provider, selectedOrigin } = props;

  return (
    <Stack
      direction='row'
      alignItems='center'
      padding={'16px 40px 0'}
      minHeight='50px'
      borderBottom='1px solid var(--mui-palette-divider)'
    >
      <Tabs
        onChange={(e, value) => onOriginSelected(value)}
        value={selectedOrigin}
        className='min-h-[50px] border-b-0'
        sx={{ '.MuiTabs-scroller': { display: 'flex' } }}
      >
        {snapshotsOrigins.map((origin) => (
          <Tab
            value={origin}
            label={origin === 'eon' ? 'Eon snapshots' : `${provider} snapshots`}
            key={origin}
            className='text-[14px] font-normal px-[12px]'
            disableRipple
          />
        ))}
      </Tabs>
    </Stack>
  );
}
