interface EonSnapshotsMultipleDotsProps {
  fill?: string;
}

export const EonSnapshotsMultipleDots = ({
  fill,
}: EonSnapshotsMultipleDotsProps) => {
  const fillColor = fill || '#272742';

  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='10' cy='12' r='6' fill={fillColor} />
      <mask id='path-2-inside-1_1235_24908' fill='white'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M15.498 17.8125C17.0389 16.3543 18.0002 14.2899 18.0002 12.001C18.0002 9.71206 17.0389 7.64766 15.498 6.18945C18.087 6.85477 20.0002 9.20445 20.0002 12.001C20.0002 14.7975 18.087 17.1472 15.498 17.8125Z'
        />
      </mask>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.498 17.8125C17.0389 16.3543 18.0002 14.2899 18.0002 12.001C18.0002 9.71206 17.0389 7.64766 15.498 6.18945C18.087 6.85477 20.0002 9.20445 20.0002 12.001C20.0002 14.7975 18.087 17.1472 15.498 17.8125Z'
        fill={fillColor}
      />
      <path
        d='M15.498 17.8125L14.8107 17.0862L12.0031 19.7431L15.7469 18.781L15.498 17.8125ZM15.498 6.18945L15.7469 5.22092L12.0031 4.2588L14.8107 6.91577L15.498 6.18945ZM17.0002 12.001C17.0002 14.0039 16.1603 15.809 14.8107 17.0862L16.1854 18.5388C17.9175 16.8996 19.0002 14.5759 19.0002 12.001H17.0002ZM14.8107 6.91577C16.1603 8.19298 17.0002 9.99808 17.0002 12.001H19.0002C19.0002 9.42603 17.9175 7.10233 16.1854 5.46313L14.8107 6.91577ZM15.2491 7.15798C17.4068 7.71247 19.0002 9.6719 19.0002 12.001H21.0002C21.0002 8.73699 18.7671 5.99708 15.7469 5.22092L15.2491 7.15798ZM19.0002 12.001C19.0002 14.33 17.4068 16.2895 15.2491 16.844L15.7469 18.781C18.7672 18.0049 21.0002 15.265 21.0002 12.001H19.0002Z'
        fill={fillColor}
        mask='url(#path-2-inside-1_1235_24908)'
      />
    </svg>
  );
};
