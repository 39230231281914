import { MenuItem, Select } from '@mui/material';

const calcOptions = [
  { key: 'now', label: 'Now' },
  { key: 'snapshot-time', label: 'Original snapshot date' },
];

export type CalcOption = 'now' | 'snapshot-time';

interface CalcRetentionFromSelectorProps {
  calcOption: CalcOption;
  onChange: (option: CalcOption) => void;
}

export const CalcRetentionFromSelector = (
  props: CalcRetentionFromSelectorProps
) => {
  return (
    <Select
      sx={{ width: '200px' }}
      size='small'
      value={props.calcOption}
      onChange={(event) => props.onChange(event.target.value as CalcOption)}
    >
      {calcOptions.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};
