import { Divider, Stack } from '@mui/material';
import type {
  AzureVmDisk,
  InventoryResource,
  Snapshot,
  InventorySnapshotVolume,
} from '@repo/api-gw-sdk';
import { Provider } from '@repo/api-gw-sdk';

import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotDetails } from './snapshotDetails';

export type RestoreActionType =
  | 'convert-snapshot'
  | 'convert-image'
  | 'volume'
  | 'full-instance';

interface VMSnapshotRowProps {
  snap: Snapshot;
  vault: BackupVault | undefined;
  onRestore: (
    vols: (AzureVmDisk | InventorySnapshotVolume)[],
    actionType: RestoreActionType
  ) => void;
  isSelected: boolean;
  onSelect: () => void;
  resource: InventoryResource;
  snapshotSelection: boolean;
}

export const VMSnapshotRow = (props: VMSnapshotRowProps) => {
  if (props.resource.cloudProvider === Provider.Azure) {
    return <AzureVMSnapshotRow {...props} />;
  }

  if (props.resource.cloudProvider === Provider.Aws) {
    return <AwsEc2SnapshotRow {...props} />;
  }

  return null;
};

const AwsEc2SnapshotRow = ({
  snap,
  vault,
  onRestore,
  isSelected,
  onSelect,
  resource,
  snapshotSelection,
}: VMSnapshotRowProps) => {
  if (!snap.resource?.properties?.awsEc2?.volumes) {
    return null;
  }

  return (
    <Stack data-testid='vm-snapshot-row' key={snap.id} className='w-full'>
      <>
        <SnapshotDetails
          resource={resource}
          isSelected={isSelected}
          onSelect={onSelect}
          snap={snap}
          vault={vault}
          snapshotSelection={snapshotSelection}
          onRestore={(volumes, actionType) => onRestore(volumes, actionType)}
          onConvertSnapshot={(volumes, actionType) =>
            onRestore(volumes, actionType)
          }
        />
        <Divider />
      </>
    </Stack>
  );
};

const AzureVMSnapshotRow = ({
  snap,
  vault,
  onRestore,
  isSelected,
  onSelect,
  resource,
  snapshotSelection,
}: VMSnapshotRowProps) => {
  return (
    <Stack data-testid='vm-snapshot-row' key={snap.id} className='w-full'>
      <SnapshotDetails
        resource={resource}
        key={snap.id}
        isSelected={isSelected}
        onSelect={onSelect}
        snap={snap}
        vault={vault}
        snapshotSelection={snapshotSelection}
        onRestore={(volumes, actionType) => onRestore(volumes, actionType)}
      />
      <Divider />
    </Stack>
  );
};
