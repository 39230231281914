import type { BackupVault } from '@/data/vaults/backupVault';

import {
  ConvertToEonDetailedColumn,
  ConvertToEonDetailedRow,
} from './convertToEonDetailedColumn';
import OldestLatestSnapshots from './oldestLatestSnapshots';

import { SnapshotIndicator } from '../SnapshotIndicator';

interface CalculatedDateRangeProps {
  oldestDate: Date;
  latestDate: Date;
  oldestExpirationDate?: Date;
  latestExpirationDate?: Date;
  selectedVault?: BackupVault; // todo: remove optional
}

export const CalculatedDateRange = (props: CalculatedDateRangeProps) => {
  const {
    oldestDate,
    latestDate,
    oldestExpirationDate,
    latestExpirationDate,
    selectedVault,
  } = props;

  return (
    <ConvertToEonDetailedRow>
      <ConvertToEonDetailedColumn>
        Calculated date range
      </ConvertToEonDetailedColumn>
      <OldestLatestSnapshots
        oldestDate={oldestDate}
        latestDate={latestDate}
        oldestExpirationDate={oldestExpirationDate}
        latestExpirationDate={latestExpirationDate}
        DotIcon={
          <SnapshotIndicator
            sx={{
              margin: '6px',
            }}
            backgroundColor={
              selectedVault?.backgroundColor ||
              'var(--mui-palette-primary-main)'
            }
          />
        }
        showTimeline={true}
        color={selectedVault?.backgroundColor}
      />
    </ConvertToEonDetailedRow>
  );
};
