import { Box, CardContent, Divider } from '@mui/material';

import {
  EncryptionComponent,
  getEncryptionKey,
} from '@/components/encryption/encryptionComponent';
import { AvailabilityZoneSelect } from '@/components/regions/availabilityZoneSelect';
import { RegionSelect } from '@/components/regions/regionSelect';
import { TagsSection } from '@/components/tags/tagsSection';
import { VolumeSettingsSection } from '@/components/volumes/volumeSettings';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark, SnapshotToolbar } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import { type RestoreVolumeState } from '../restoreVolumeWizard';

export const ConvertToEbsSnapshotConfiguration = (
  props: StepProps<RestoreVolumeState>
) => {
  const dal = useDAL();

  const canGoNext = () => {
    if (
      !props.wizardState.regionName ||
      !getEncryptionKey(props.wizardState.encryption)
    ) {
      return false;
    }

    if (
      props.wizardState.actionType === 'volume' &&
      !props.wizardState.availabilityZone
    ) {
      return false;
    }

    return true;
  };

  const onNextClick = () => {
    const tags =
      (props.wizardState.keepOriginalTags
        ? props.wizardState.initialTags
        : props.wizardState.tags) || {};
    tags['eon:restore'] = 'true';

    if (props.wizardState.actionType === 'convert-snapshot') {
      void dal.convert
        .volume(props.wizardState.resourceId, props.wizardState.snapshot.id, {
          providerVolumeId: props.wizardState.providerVolumeId,
          tags,
          regionName: props.wizardState.regionName ?? '',
          restoreAccountId: props.wizardState.restoreAccount!.id,
          encryptionKeyId: getEncryptionKey(props.wizardState.encryption),
          snapshotEncryptionKeyId: props.wizardState.encryption?.isEncrypted
            ? getEncryptionKey(props.wizardState.encryption)
            : undefined,
        })
        .then(() => {
          props.abort();
          props.onSuccess('Convert snapshot has started!');
        });
    } else if (props.wizardState.actionType === 'volume') {
      const volumeSettings = props.wizardState.keepOriginalSettings
        ? props.wizardState.initialVolumeSettings
        : props.wizardState.volumeSettings;
      void dal.restore
        .volume(props.wizardState.resourceId, props.wizardState.snapshot.id, {
          providerVolumeId: props.wizardState.providerVolumeId,
          tags,
          restoreAccountId: props.wizardState.restoreAccount!.id,
          encryptionKeyId: getEncryptionKey(props.wizardState.encryption),
          availabilityZone: props.wizardState.availabilityZone!,
          volumeSettings: volumeSettings!,
          volumeEncryptionKeyId: props.wizardState.encryption?.isEncrypted
            ? getEncryptionKey(props.wizardState.encryption)
            : undefined,
        })
        .then(() => {
          props.onSuccess('Restore job has started!');
          props.abort();
        });
    }
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      navigationComponent={<EonTagRemark />}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={props.back}
      canGoNext={canGoNext}
      onNextClick={onNextClick}
      toolbarComponent={
        <SnapshotToolbar
          pointInTime={props.wizardState.snapshot.pointInTime}
          vaultId={props.wizardState.snapshot.vaultId}
          accessDeniedItemsCount={
            props.wizardState.snapshot.accessDeniedItemsCount
          }
        />
      }
    >
      <Box className='mx-[40px] my-[24px]'>
        <Box className='w-full flex justify-between mt-[20px] gap-[36px]'>
          <RegionSelect
            accountId={props.wizardState.restoreAccount?.id}
            regionName={props.wizardState.regionName}
            onChange={(region) =>
              props.setWizardState((state) => ({
                ...state,
                regionName: region,
                availabilityZone: undefined,
              }))
            }
          />
          {props.wizardState.actionType === 'volume' && (
            <AvailabilityZoneSelect
              accountId={props.wizardState.restoreAccount?.id}
              regionName={props.wizardState.regionName}
              availabilityZone={props.wizardState.availabilityZone}
              onChange={(availabilityZone) =>
                props.setWizardState((state) => ({
                  ...state,
                  availabilityZone,
                }))
              }
            />
          )}
        </Box>
      </Box>
      <Divider />
      <CardContent className='p-0'>
        {props.wizardState.regionName && (
          <EncryptionComponent
            encryption={props.wizardState.encryption}
            accountId={props.wizardState.restoreAccount!.id}
            regionName={props.wizardState.regionName}
            onChange={(encryption) =>
              props.setWizardState((state) => ({
                ...state,
                encryption,
              }))
            }
          />
        )}
      </CardContent>
      {props.wizardState.actionType === 'volume' && (
        <>
          <Divider />
          <CardContent className='p-0'>
            <VolumeSettingsSection
              initialSettings={props.wizardState.initialVolumeSettings}
              settings={props.wizardState.volumeSettings}
              onChange={(volumeSettings) =>
                props.setWizardState((state) => ({ ...state, volumeSettings }))
              }
              keepOriginalSettings={props.wizardState.keepOriginalSettings}
              setKeepOriginalSettings={(value: boolean) =>
                props.setWizardState((state) => ({
                  ...state,
                  keepOriginalSettings: value,
                }))
              }
            />
          </CardContent>
        </>
      )}
      <Divider />
      <CardContent className='p-0'>
        <TagsSection
          initialTags={props.wizardState.initialTags || {}}
          tags={props.wizardState.tags || props.wizardState.initialTags || {}}
          keepOriginalTags={props.wizardState.keepOriginalTags}
          setKeepOriginalTags={(value: boolean) =>
            props.setWizardState((state) => ({
              ...state,
              keepOriginalTags: value,
            }))
          }
          onChange={(tags) =>
            props.setWizardState((state) => ({ ...state, tags }))
          }
        />
      </CardContent>
    </StepContainer>
  );
};
