import { Stack } from '@mui/material';
import type { InventoryResource, Snapshot } from '@repo/api-gw-sdk';

import type { BackupVault } from '@/data/vaults/backupVault';

import { SnapshotActionsGroup } from './snapshotActionsGroup';
import { SnapshotDetailsHeader } from './snapshotDetailsHeader';

export const SnapshotRow = ({
  snap,
  vault,
  isSelected,
  onSelect,
  onRestore,
  resource,
  snapshotSelection,
}: {
  snap: Snapshot;
  vault: BackupVault | undefined;
  isSelected: boolean;
  onSelect: () => void;
  onRestore: () => void;
  volume?: string;
  resource: InventoryResource;
  snapshotSelection: boolean;
}) => {
  return (
    <Stack
      data-testid='snapshot-row'
      borderBottom='1px solid var(--mui-palette-divider)'
      className='py-[24px] px-[40px] w-full'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      key={snap.id}
    >
      <SnapshotDetailsHeader
        snap={snap}
        vault={vault}
        onSelect={onSelect}
        isSelected={isSelected}
        snapshotSelection={snapshotSelection}
      />
      <SnapshotActionsGroup
        snapshot={snap}
        isVisible={!snapshotSelection || isSelected}
        onRestore={onRestore}
        resource={resource}
      />
    </Stack>
  );
};
