import { Stack, Typography } from '@mui/material';
import type {
  ConvertOtherSnapshotsOnDemandRequest,
  ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner,
  InventoryResource,
  Provider,
} from '@repo/api-gw-sdk';
import { useEffect, useState, type Dispatch, type SetStateAction } from 'react';

import { useWorkspace } from '@/contexts/useWorkspace';
import { useDAL } from '@/data/dal';
import { CloudProviders } from '@/data/inventory/data';
import type { BackupVault } from '@/data/vaults/backupVault';
import dayjs, { DATE_FORMAT } from '@/utils/dayjs';

import { PanelWrapper } from './panelWrapper';

import { SnapshotIndicator } from '../snapshots/SnapshotIndicator';
import type { CalcOption } from '../snapshots/convertToEon/calcRetentionFromSelector';
import ConvertLifetimeLayout from '../snapshots/convertToEon/convertLifetimeLayout';
import { ConvertToEonProviderDetails } from '../snapshots/convertToEon/convertToEonProviderDetails';
import ConvertToEonTargetDetails from '../snapshots/convertToEon/convertToEonTargetDetails';
import { OtherSnapshotDot } from '../snapshots/otherSnapshotDot';

interface ConvertToSingleEonSnapshotPanelProps {
  resource: InventoryResource;
  snapshotDetails: ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner[];
  snapshotDate: string;
  onClose: () => void;
  back: () => void;
}

export default function ConvertToSingleEonSnapshotPanel(
  props: ConvertToSingleEonSnapshotPanelProps
) {
  const dal = useDAL();
  const { snackbar } = useWorkspace();
  const [isInvalidRetention, setIsInvalidRetention] = useState(false);
  const { resource, snapshotDetails, snapshotDate, onClose, back } = props;
  const [convertDetails, setConvertDetails] =
    useState<ConvertOtherSnapshotsOnDemandRequest>({
      vaultId: '',
      retentionDays: 0,
      snapshotDetails,
    });

  return (
    <PanelWrapper
      header={{
        onBack: back,
        title: 'Convert to Eon snapshot',
        onClose,
      }}
      footer={{
        primaryButton: {
          'data-testid': 'convert-to-single-eon-button',
          icon: 'material-symbols-join-right-rounded',
          text: 'Convert',
          onClick: async () => {
            await dal.convert.otherSnapshotOnDemand(
              resource.id,
              convertDetails
            );
            onClose();
            snackbar.showMessage(
              `Backup jobs started for ${resource.resourceName}`
            );
          },
          disabled: isInvalidRetention,
        },
        secondaryButton: {
          'data-testid': 'convert-to-eon-cancel-button',
          text: 'Cancel',
          onClick: back,
        },
      }}
      testIdPrefix='convert-to-eon-snapshots-panel'
    >
      <ConvertToEonSingleBody
        setConvertDetails={setConvertDetails}
        snapshotDetails={snapshotDetails}
        snapshotDate={snapshotDate}
        setIsInvalidRetention={setIsInvalidRetention}
        isInvalidRetention={isInvalidRetention}
        cloudProvider={resource.cloudProvider}
      />
    </PanelWrapper>
  );
}

interface ConvertToEonSingleBodyProps {
  setConvertDetails: Dispatch<
    SetStateAction<ConvertOtherSnapshotsOnDemandRequest>
  >;
  snapshotDetails: ConvertOtherSnapshotsOnDemandRequestSnapshotDetailsInner[];
  snapshotDate: string;
  setIsInvalidRetention: (value: boolean) => void;
  isInvalidRetention: boolean;
  cloudProvider: Provider;
}

const ConvertToEonSingleBody = ({
  setConvertDetails,
  snapshotDetails,
  snapshotDate,
  setIsInvalidRetention,
  isInvalidRetention,
  cloudProvider,
}: ConvertToEonSingleBodyProps) => {
  const [calcOption, setCalcOption] = useState<CalcOption>('now');
  const [selectedVault, setSelectedVault] = useState<BackupVault>();
  const [retentionInDays, setRetentionInDays] = useState(90);

  const getRetentionForConvertCall = () => {
    if (calcOption === 'now') {
      return retentionInDays;
    }
    const expirationDate = dayjs
      .utc(snapshotDate)
      .add(retentionInDays, 'day')
      .startOf('day');
    const today = dayjs.utc().startOf('day');
    return expirationDate.diff(today, 'day');
  };

  useEffect(() => {
    if (selectedVault) {
      setConvertDetails((prev) => ({
        ...prev,
        vaultId: selectedVault.id,
      }));
    }
  }, [selectedVault, setConvertDetails]);

  useEffect(() => {
    setConvertDetails((prev) => ({
      ...prev,
      retentionDays: getRetentionForConvertCall(),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retentionInDays, setRetentionInDays]);

  useEffect(() => {
    setConvertDetails((prev) => ({
      ...prev,
      retentionDays: getRetentionForConvertCall(),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcOption]);

  const fields = [
    {
      label: 'Snapshot date',
      value: dayjs.utc(snapshotDate).format(DATE_FORMAT),
    },
    {
      label: 'Volume snapshot IDs',
      value: (
        <Stack className='flex-1' direction='column' gap='16px'>
          {snapshotDetails.map((snap) => {
            const snapId = snap.snapshotLocation.snapshotId;
            return (
              <Typography key={snapId} width='100%'>
                {snapId}
              </Typography>
            );
          })}
        </Stack>
      ),
    },
  ];

  return (
    <ConvertLifetimeLayout
      description={
        <Typography
          width='90%'
          paddingX='40px'
          lineHeight={'20px'}
          fontWeight={300}
          variant='subtitle1'
          fontSize={14}
        >
          The {CloudProviders[cloudProvider]?.displayName} snapshot will be
          copied and converted to Eon’s snapshot format, allowing you to search
          and explore your data. You can set the retention based on the current
          date or the original snapshot date.
          <br /> <br />
          The original snapshot won’t be deleted.
        </Typography>
      }
      source={{
        dot: <OtherSnapshotDot />,
        title: `${cloudProvider} snapshot`,
        details: <ConvertToEonProviderDetails fields={fields} />,
      }}
      target={{
        dot: (
          <SnapshotIndicator
            sx={{
              margin: '6px',
            }}
            backgroundColor={selectedVault?.backgroundColor || ''}
          />
        ),
        title: 'Eon snapshot',
        details: (
          <ConvertToEonTargetDetails
            setSelectedVault={setSelectedVault}
            selectedVault={selectedVault}
            setRetentionInDays={setRetentionInDays}
            retentionInDays={retentionInDays}
            calcOption={calcOption}
            setCalcOption={setCalcOption}
            snapshotDate={dayjs.utc(snapshotDate).toDate()}
            setIsInvalidRetention={setIsInvalidRetention}
            isInvalidRetention={isInvalidRetention}
          />
        ),
      }}
    />
  );
};
