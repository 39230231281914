import { Box, Stack } from '@mui/material';

export const DashedArrow = () => {
  return (
    <Stack alignItems='center' height='100%' width='24px'>
      {/* Dashed Line */}
      <Box
        sx={{
          borderLeft: '0.1em dashed black',
          height: '100%',
          width: '0.1em',
        }}
      />
      {/* Arrow (Lines) */}
      <Box
        sx={{
          position: 'relative',
          marginTop: '0.3em', // Adjust spacing
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          fontSize: '1em', // Controls scalable arrow size
        }}
      >
        {/* Left Diagonal Line */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '-0.42em', // Scalable position
            borderTop: '0.1em solid black',
            width: '0.5em', // Scalable width
            transform: 'rotate(40deg)',
          }}
        />
        {/* Right Diagonal Line */}
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: '-0.43em', // Scalable position
            borderTop: '0.1em solid black',
            width: '0.5em', // Scalable width
            transform: 'rotate(-40deg)',
          }}
        />
      </Box>
    </Stack>
  );
};
