import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';

import { useDAL } from '@/data/dal';

import { EmptySelect } from './emptySelect';

import SelectPlaceholder from '../selectPlaceholder';

const title = 'RDS subnet group';

export const RDSSubnetGroupSelect = ({
  accountId,
  regionName,
  rdsSubnetGroupName,
  onChange,
}: {
  accountId?: string;
  regionName?: string;
  rdsSubnetGroupName?: string;
  onChange: (rdsSubnetGroupName: string, vpc: string) => void;
}) => {
  if (!accountId || !regionName) {
    return <EmptySelect title={title} />;
  }

  return (
    <InnerRDSSubnetGroupSelect
      accountId={accountId}
      regionName={regionName}
      rdsSubnetGroupName={rdsSubnetGroupName}
      onChange={onChange}
    />
  );
};

const InnerRDSSubnetGroupSelect = ({
  accountId,
  regionName,
  rdsSubnetGroupName,
  onChange,
}: {
  accountId: string;
  regionName: string;
  rdsSubnetGroupName?: string;
  onChange: (rdsSubnetGroupName: string, vpc: string) => void;
}) => {
  const dal = useDAL();
  const { body } = dal.restore.rdsSubnetGroup.list(accountId, regionName);

  if (!body?.rdsSubnetGroups.length) {
    return <EmptySelect title={title} />;
  }

  return (
    <FormControl size='small' className='flex-1'>
      <FormLabel>{title}</FormLabel>
      <Select
        data-testid='rdsSubnetGroupSelect'
        size='small'
        value={rdsSubnetGroupName || ''}
        displayEmpty
        renderValue={(value: string) => {
          if (!value) {
            return <SelectPlaceholder />;
          }
          return value;
        }}
        onChange={(event) =>
          onChange(
            event.target.value,
            body.rdsSubnetGroups.find((x) => x.name === event.target.value)
              ?.vpc || ''
          )
        }
      >
        {body.rdsSubnetGroups.map((value, idx) => (
          <MenuItem
            data-testid={`rdsSubnetGroupOption-${idx}`}
            key={value.name}
            value={value.name}
          >
            {`${value.name} (${value.vpc})`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
