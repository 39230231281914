import { Box, CardContent, Divider, Stack } from '@mui/material';
import { useState } from 'react';

import { ResourceGroupSelect } from '@/components/regions/resourceGroupSelect';
import { TagsSection } from '@/components/tags/tagsSection';
import { DiskSettingsSection } from '@/components/volumes/diskSettings';
import type { StepProps } from '@/components/wizard/StepProps';
import { useDAL } from '@/data/dal';

import { EonTagRemark, SnapshotToolbar } from './shared';

import { StepContainer } from '../../wizard/StepContainer';
import type { RestoreAzureDiskState } from '../restoreAzureDiskWizard';

export const AzureDiskConfiguration = (
  props: StepProps<RestoreAzureDiskState>
) => {
  const [error, setError] = useState<string | undefined>();
  const dal = useDAL();
  const diskSettings = props.wizardState.keepOriginalSettings
    ? props.wizardState.initialDiskSettings
    : props.wizardState.diskSettings!;
  const onNextClick = () => {
    setError(undefined);
    const tags = {
      ...(props.wizardState.keepOriginalTags
        ? props.wizardState.initialTags
        : props.wizardState.tags),
      'eon:restore': 'true',
    };

    void dal.restore.azureVm
      .restoreDisk(
        props.wizardState.resourceId,
        props.wizardState.snapshot.id,
        {
          restoreAccountId: props.wizardState.restoreAccount!.id,
          diskId: diskSettings.diskId,
          diskName: diskSettings.diskName,
          diskTier: diskSettings.diskTier,
          diskType: diskSettings.diskType,
          location: props.wizardState.location!,
          resourceGroup: props.wizardState.resourceGroup!,
          diskSizeBytes: diskSettings.diskSizeBytes,
          diskHyperVGeneration: diskSettings.diskHyperVGeneration,
          tags,
        }
      )
      .then(() => {
        props.abort();
        props.onSuccess('Restore job has started!');
      })
      .catch((e) => {
        setError(`Failed to restore vm: ${e.message}`);
      });

    props.abort();
    props.onSuccess('Restore job has started!');
  };

  return (
    <StepContainer
      sx={{ padding: '0' }}
      stepperLabels={props.stepperLabels}
      stepperIndex={props.stepperLabels.length - 1}
      nextButtonText='Restore'
      onBackClick={props.back}
      canGoNext={() =>
        !!diskSettings?.diskId &&
        !!diskSettings?.diskName &&
        !!diskSettings?.diskTier &&
        !!diskSettings?.diskType &&
        !!props.wizardState.location &&
        !!props.wizardState.resourceGroup &&
        !!diskSettings.diskSizeBytes
      }
      navigationComponent={
        error ? (
          <Stack alignItems='center' direction='row' sx={{ color: 'red' }}>
            <i className='ri-error-warning-line mr-[8px] text-[18px]' />
            {error}
          </Stack>
        ) : (
          <EonTagRemark />
        )
      }
      onNextClick={onNextClick}
      toolbarComponent={
        <SnapshotToolbar
          pointInTime={props.wizardState.snapshot.pointInTime}
          vaultId={props.wizardState.snapshot.vaultId}
          accessDeniedItemsCount={
            props.wizardState.snapshot.accessDeniedItemsCount
          }
        />
      }
    >
      <Box className='mx-[40px] my-[24px]'>
        <Stack direction='row' gap='24px' className='mt-[20px] mb-[24px]'>
          <ResourceGroupSelect
            accountId={props.wizardState.restoreAccount?.id}
            resourceGroup={props.wizardState.resourceGroup}
            onChange={(group) =>
              props.setWizardState((state) => ({
                ...state,
                resourceGroup: group?.name,
                location: group?.location,
              }))
            }
          />
        </Stack>
      </Box>
      <Divider />
      <CardContent className='p-0'>
        <DiskSettingsSection
          initialSettings={props.wizardState.initialDiskSettings}
          settings={props.wizardState.diskSettings}
          accountId={props.wizardState.restoreAccount?.id ?? ''}
          location={props.wizardState.location ?? ''}
          keepOriginalSettings={props.wizardState.keepOriginalSettings}
          onChange={(diskSettings) =>
            props.setWizardState((state) => ({ ...state, diskSettings }))
          }
          setKeepOriginalSettings={(value: boolean) =>
            props.setWizardState((state) => ({
              ...state,
              keepOriginalSettings: value,
            }))
          }
        />
      </CardContent>
      <Divider />
      <CardContent className='px-0'>
        <TagsSection
          initialTags={props.wizardState.initialTags ?? {}}
          tags={props.wizardState.tags ?? {}}
          keepOriginalTags={props.wizardState.keepOriginalTags}
          setKeepOriginalTags={(keepOriginalTags) =>
            props.setWizardState((state) => ({ ...state, keepOriginalTags }))
          }
          onChange={(tags) =>
            props.setWizardState((state) => ({ ...state, tags }))
          }
        />
      </CardContent>
    </StepContainer>
  );
};
