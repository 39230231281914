import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  Stack,
  Typography,
} from '@mui/material';
import type { BackupSchedule } from '@repo/api-gw-sdk';
import { useEffect, useState } from 'react';

import ButtonWithIcon from '@/components/buttons/buttonWithIcon';
import MainDialog from '@/components/dialogs/mainDialog';
import {
  ScheduleMenuItem,
  ScheduleSelect,
} from '@/components/schedule/schedule';
import { StepContainer } from '@/components/wizard/StepContainer';
import type { StepProps } from '@/components/wizard/StepProps';
import { RenameEntityName } from '@/components/wizard/renameEntityName';
import { useFeatureFlags } from '@/contexts/useFeatureFlags';

import type { BackupPolicyEditState } from './BackupPolicyCreationFlow';
import { BackupPolicySchedule } from './BackupPolicySchedule';

export const BackupPolicySettings = (
  props: StepProps<BackupPolicyEditState>
) => {
  const { advancedBackupPolicy } = useFeatureFlags();
  const [changeScheduleTypeDialogOpen, setChangeScheduleTypeDialogOpen] =
    useState(false);
  const [useUtc, setUseUtc] = useState(
    !!props.wizardState.schedules?.[0]?.useUtc
  );

  const addSchedule = () => {
    const newState = {
      ...props.wizardState,
    };

    newState.schedules = [
      ...(newState.schedules ?? []),
      {
        id: crypto.randomUUID(),
        policyId: '',
        duration: 24 * 60, // 24 hours default
        backupRetention: 30,
        vaultId: '',
        windows: '0 0 * * *',
      },
    ];

    props.setWizardState(newState);
  };

  const updateSchedule = (schedule: BackupSchedule) => {
    const schedules =
      props.wizardState.schedules?.map((s) =>
        s.id === schedule.id ? schedule : s
      ) ?? [];

    props.setWizardState({ ...props.wizardState, schedules });
  };

  const removeSchedule = (schedule: BackupSchedule) => {
    if (props.wizardState.schedules?.length === 1) return;
    props.setWizardState({
      ...props.wizardState,
      schedules: props.wizardState.schedules?.filter(
        (s) => s.id !== schedule.id
      ),
    });
  };

  const duplicateSchedule = (schedule: BackupSchedule) => {
    props.setWizardState({
      ...props.wizardState,
      schedules: [
        ...(props.wizardState.schedules ?? []),
        { ...schedule, id: crypto.randomUUID() },
      ],
    });
  };

  return (
    <>
      <StepContainer
        navigationComponent={
          <RenameEntityName
            label='Backup policy name'
            name={props.wizardState.name}
            onChange={(name) =>
              props.setWizardState((state) => ({ ...state, name }))
            }
          />
        }
        stepperLabels={props.stepperLabels}
        stepperIndex={2}
        onBackClick={props.back}
        canGoNext={() => {
          if (!props.wizardState.schedules?.length) {
            return false;
          }

          for (const schedule of props.wizardState.schedules) {
            if (!schedule.vaultId) {
              return false;
            }
          }

          return true;
        }}
        nextButtonText={props.wizardState.id ? 'Save Changes' : 'Create'}
        onNextClick={() => {
          props.finish();
        }}
      >
        <Stack
          className='m-[60px]'
          direction='row'
          justifyContent='space-between'
        >
          <Stack>
            <Typography variant='body1' sx={{ mb: '16px' }}>
              Set your frequency, retention and vault:
            </Typography>
            {props.wizardState.schedules?.map((schedule) => (
              <BackupPolicySchedule
                deleteAllowed={(props.wizardState.schedules?.length ?? 0) > 1}
                schedule={schedule}
                key={schedule.id}
                onUpdate={updateSchedule}
                onDuplicate={duplicateSchedule}
                onDelete={removeSchedule}
                basic={props.wizardState.basic}
              />
            ))}
            <Box sx={{ marginTop: '16px' }}>
              <ButtonWithIcon
                data-testid='create-backup-policy-add-backup-schedule-btn'
                icon='material-symbols-add-rounded'
                text='Add Another'
                onClick={addSchedule}
              />
            </Box>
          </Stack>
          {advancedBackupPolicy && (
            <Stack>
              <Stack direction='row' alignItems='center' gap='12px'>
                {!props.wizardState.basic && (
                  <>
                    <Typography>Schedules are in</Typography>
                    <ScheduleSelect
                      MenuProps={{
                        disablePortal: true,
                        anchorOrigin: {
                          vertical: 'top',
                          horizontal: 'center',
                        },
                        sx: {
                          marginTop: '-2px',
                        },
                      }}
                      value={useUtc ? 'utc' : 'resource'}
                      onChange={(e) => {
                        setUseUtc(e.target.value === 'utc');
                        props.setWizardState((state) => ({
                          ...state,
                          schedules: state.schedules?.map((s) => ({
                            ...s,
                            useUtc: e.target.value === 'utc',
                          })),
                        }));
                      }}
                      variant='standard'
                      data-testid='create-backup-policy-frequency-select'
                    >
                      <ScheduleMenuItem value='resource'>
                        <Typography variant='body1'>
                          Resource time zone
                        </Typography>
                      </ScheduleMenuItem>
                      <ScheduleMenuItem value='utc'>
                        <Typography variant='body1'>UTC</Typography>
                      </ScheduleMenuItem>
                    </ScheduleSelect>
                    <Divider
                      orientation='vertical'
                      flexItem
                      className='mr-[12px]'
                    />
                  </>
                )}
                <Typography>Schedule type</Typography>
                <Button
                  sx={{
                    padding: '5px 12px',
                  }}
                  onClick={() => setChangeScheduleTypeDialogOpen(true)}
                  data-testid='create-backup-policy-retention-select'
                  disableFocusRipple
                >
                  <Typography fontWeight={600}>
                    {props.wizardState.basic ? 'Basic' : 'Advanced'}
                  </Typography>

                  <i className='material-symbols-arrow-drop-down-rounded text-[22px] ml-[4px]' />
                </Button>
                <ChangeScheduleTypeDialog
                  open={changeScheduleTypeDialogOpen}
                  basic={!!props.wizardState.basic}
                  onCancel={() => setChangeScheduleTypeDialogOpen(false)}
                  onSelect={(basic) => {
                    setChangeScheduleTypeDialogOpen(false);
                    props.setWizardState((state) => ({
                      ...state,
                      basic,
                      schedules: state.schedules?.map((s) => ({
                        ...s,
                        useUtc: !basic,
                      })),
                    }));

                    if (basic) {
                      setUseUtc(false);
                    }
                  }}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </StepContainer>
    </>
  );
};

const ChangeScheduleTypeDialog = ({
  open,
  basic,
  onCancel,
  onSelect,
}: {
  open: boolean;
  basic: boolean;
  onCancel: () => void;
  onSelect: (basic: boolean) => void;
}) => {
  const [basicValue, setBasicValue] = useState(basic);

  useEffect(() => {
    setBasicValue(basic);
  }, [basic]);

  return (
    <MainDialog
      isOpen={open}
      isLoading={false}
      header={{
        title: 'Schedule type',
        onClose: () => onCancel(),
      }}
      footer={{
        primaryButton: {
          'data-testid': 'approve-change-schedule-type',
          text: 'Select',
          onClick: () =>
            basic === basicValue ? onCancel() : onSelect(basicValue),
        },
        secondaryButton: {
          'data-testid': 'cancel-change-schedule-type',
          onClick: () => onCancel(),
          text: 'Cancel',
        },
      }}
    >
      <Stack gap='40px' className='my-[20px]'>
        <Stack direction='row' className='mx-[40px]' gap='60px'>
          <Stack gap='12px'>
            <FormControlLabel
              control={<Radio className='mr-[12px]' />}
              label='Basic schedule'
              checked={basicValue}
              onChange={(event, checked) => setBasicValue(checked)}
            />
            <Typography className='ml-[48px] w-[200px]' variant='body2'>
              You set the frequency, retention, and vault. Eon sets the
              schedule.
            </Typography>
          </Stack>
          <Divider orientation='vertical' flexItem />
          <Stack gap='12px'>
            <Typography lineHeight='20px'>
              Backups start between 00:00 and 04:00 in the resource time zones.
            </Typography>
            <Stack gap='8px'>
              <Stack direction='row' alignItems='center' gap='12px'>
                <Typography className='w-[80px]'>Weekly</Typography>
                <Typography>Every Sunday</Typography>
              </Stack>
              <Stack direction='row' alignItems='center' gap='12px'>
                <Typography className='w-[80px]'>Monthly</Typography>
                <Typography>Every first of the month</Typography>
              </Stack>
              <Stack direction='row' alignItems='center' gap='12px'>
                <Typography className='w-[80px]'>Yearly</Typography>
                <Typography>Every January 1st</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction='row' className='mx-[40px]' gap='60px'>
          <Stack gap='12px'>
            <FormControlLabel
              control={<Radio className='mr-[12px]' />}
              label='Advanced schedule'
              checked={!basicValue}
              onChange={(event, checked) => setBasicValue(!checked)}
            />
            <Typography className='ml-[48px] w-[200px]' variant='body2'>
              Tailor the schedule to your company’s needs.
            </Typography>
          </Stack>
          <Divider orientation='vertical' flexItem />
          <Stack gap='4px'>
            <List sx={{ listStyle: 'disc', pl: 4 }}>
              <ListItem sx={{ display: 'list-item', padding: '0 0 4px 0' }}>
                <ListItemText primary='Customize backup start time range.' />
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 4px 0' }}>
                <ListItemText primary='Change schedule time zone.' />
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: '0 0 4px 0' }}>
                <ListItemText
                  primary='Set one or more backups per day, week, month, or
          year.'
                />
              </ListItem>
              <ListItem sx={{ display: 'list-item', padding: 0 }}>
                <ListItemText primary='Choose specific days or dates.' />
              </ListItem>
            </List>
          </Stack>
        </Stack>
      </Stack>
    </MainDialog>
  );
};
